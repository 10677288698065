@charset "UTF-8";

.center {
  margin: 0 auto !important;
}

.u-sp-none {
  @include respond(sp) {
    display: none !important;
  }
}
.u-pc-none {
  @include respond(tab-port) {
    display: none !important;
  }
}
.u-sp-mb-43 {
  display: block;
  @include respond(sp) {
    margin-bottom: 4.3rem;
  }
}