@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .case-1 {
    padding: 20rem 0 0;
    background-color: $color-beige-1;

    &__wrapper {
      position: relative;
    }
    &__container {
      display: flex;
      flex-direction: column-reverse;
    }
    &__text-container {
      z-index: 10;
      background-color: $color-white;
      padding-left: 8.5rem;
      padding-right: 8.5rem;
      padding-top: 22rem;
      padding-bottom: 12rem;
      margin-top: -10.6rem;
    }
    &__heading-primary {
      color: $color-primary;
      font-size: 13.6rem;
      position: relative;
      z-index: 100;
      font-family: $font-garamond;
      writing-mode: vertical-rl;
      letter-spacing: 0.1em;
      line-height: 0.7;
      padding-left: 0.5rem;
      position: absolute;
      top: -13.4rem;
      left: 3rem;
    }
    &__heading-secondary {
      font-family: $font-aotf-pro;
      font-size: 4.8rem;
      line-height: 1;
      letter-spacing: 0.05em;
      margin-bottom: 6.8rem;
      font-feature-settings: 'palt';
      text-align: center;
    }
    &__text {
      font-size: 3.1rem;
      line-height: 1.87;
      letter-spacing: 0.08em;
    }
    &__image-container {
      position: relative;
      z-index: 20;
      justify-self: end;
      margin-left: 5.8rem;
    }
    &__image-1 {
      object-position: left;
      width: 100%;
      height: 51rem;
      object-fit: cover;
    }
    &__button-container {
      margin-top: 8rem;
      & .arrow {
        transform: translateX(9rem) rotate(45deg);
      }
      & .button {
        margin: 0 auto;
      }
    }
  }
}
/* PC
*******************/
@include respond(tab-port) {
  .case-1 {
    padding: 30rem 0 23.65rem;
    background-color: $color-beige-1;

    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      position: relative;
    }
    &__container {
      display: grid;
      grid-template-columns: 12.5rem 1fr;
      margin-left: 9.5rem;
    }
    &__text-container {
      position: absolute;
      z-index: 50;
      max-width: 72.4rem;
      background-color: $color-white;
      padding-left: 22rem;
      padding-right: 5.6rem;
      padding-top: 8.6rem;
      padding-bottom: 6.6rem;
      bottom: -20%;
      left: 0;
    }
    &__heading-primary {
      color: $color-primary;
      font-size: 9rem;
      position: relative;
      z-index: 100;
      align-self: flex-end;
      padding-bottom: 14.5rem;
      letter-spacing: 0.1em;
      font-family: $font-garamond;
      writing-mode: vertical-rl;
    }
    &__heading-secondary {
      font-family: $font-aotf-pro;
      font-size: 2.9rem;
      line-height: 2;
      letter-spacing: 0.05em;
      margin-bottom: 4.4rem;
    }
    &__text {
      line-height: 1.875;
      letter-spacing: 0.05em;
    }
    &__image-container {
      position: relative;
      z-index: 20;
      max-width: 115rem;
      justify-self: end;
    }
    &__image-1 {
      min-height: 70rem; //要調整
      object-position: left;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
    &__button-container {
      margin-top: 3rem;
    }
  }
}
