@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5000;
    background-color: $color-beige-1;
    &.fixed {
      background-color: $color-white;
    }
    & * {
      outline: 0;
    }
    &__logo {
      & img {
        width: 32.5rem;
        height: 3.34rem;
      }
      & a {
        padding-top: 5rem;
        padding-bottom: 5rem;
        display: block;
      }
    }
    &__wrapper {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
      -ms-flex-pack: justify;
      justify-content: space-between;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
    }
    &__nav {
      display: none;
    }
    &__list {
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
      flex-direction: column;
      z-index: 5000;
      padding-top: 22.55rem;
      padding-bottom: 17.95rem;
      margin: 0 5.8rem;
    }
    &__link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4.75rem 2.7rem;
      font-size: 3.8rem;
      letter-spacing: 0.05em;
      line-height: 1;
      & .arrow {
        margin-left: 1rem;
        border-color: #b5b5b5;
        width: 2.9rem;
        height: 2.9rem;
      }
    }
    &__item {
      position: relative;
      cursor: pointer;
      width: 100%;
      margin: 0 5.8rem;
      border-bottom: 1.5px solid #b5b5b5;
      &:first-child {
        border-top: 1.5px solid #b5b5b5;
      }
      & ul {
        display: none;
        & li {
          position: relative;
          transition: 0.3s ease;
          padding: 4.75rem 2.7rem 4.75rem;
          margin-left: 2.7rem;
          font-size: 3.8rem;
          letter-spacing: 0.05em;
          line-height: 1;
          &:not(:last-child) {
            border-bottom: 1.5px solid #b5b5b5;
          }
          & a {
            @include absolute(10);
            cursor: pointer;
          }
          @include respond(hover) {
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }

    // accordion open
    &__link {
      &.is-active {
        & .arrow {
          transform: rotate(225deg);
        }
      }
    }

    /* MENU
    ******************/
    &-menu {
      transition: 0.3s $ease-out;
      transform: translateY(-100%);
      display: block;
      min-height: 100%;
      max-width: 100%;
      position: fixed;
      z-index: 4500;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      outline: 0;
      background-color: $color-white;
      & * {
        transition: 0.8s $ease-out 0.2s;
        opacity: 0;
      }
      &.hide,
      &.show.hide {
        & * {
          opacity: 0;
        }
      }
      &.show {
        transform: none;
        & * {
          opacity: 1;
        }
      }
    }
  }

  // indexページ
  .header {
    &.header-2 {
      background-color: transparent;
      position: fixed;
      top: 0;
      &.fixed {
        background-color: $color-white;
      }
      & .header__link {
        color: $color-black;
        transition: 0.3s ease;
        @include respond(hover) {
          &:hover {
            color: $color-primary;
            & .arrow {
              border-bottom: 1px solid $color-primary;
              border-right: 1px solid $color-primary;
            }
          }
        }
      }
      & .header__item {
        & ul {
          & li {
            background-color: transparent;
          }
        }
      }
      &.inview {
        background-color: $color-beige-1;
        transition: 0.3s ease;
        & .header__link {
          color: $color-black;
          & .arrow {
            border-bottom: 1px solid $color-black;
            border-right: 1px solid $color-black;
          }
          @include respond(hover) {
            &:hover {
              color: $color-primary;
              & .arrow {
                border-bottom: 1px solid $color-primary;
                border-right: 1px solid $color-primary;
              }
            }
          }
        }
        & .header__item {
          & ul {
            & li {
              background-color: $color-beige-1;
            }
          }
        }
      }
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .header {
    width: 100%;
    position: fixed;
    z-index: 5000;
    background-color: $color-beige-1;
    & * {
      outline: 0;
    }
    &-menu {
      display: none;
    }
    &__wrapper {
      max-width: 144rem;
      margin: 0 auto;
      padding-right: 2.7rem;
      padding-left: 4rem;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    &__list,
    &__wrapper {
      display: flex;
      -ms-flex-align: center;
      align-items: center;
    }
    &__list {
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
      z-index: 5000;
    }
    &__link {
      display: block;
      padding: 4rem 1.3rem;
      font-size: 1.5rem;
      letter-spacing: 0.05em;
      line-height: 2;
      & .arrow {
        margin-left: 1rem;
      }
    }
    &__item {
      position: relative;
      cursor: pointer;
      & ul {
        position: absolute;
        left: 0;
        top: 10.8rem;
        display: none;
        & li {
          position: relative;
          width: 22rem;
          padding: 2rem 1.3rem;
          background-color: $color-beige-1;
          transition: 0.3s ease;
          & a {
            @include absolute(10);
            cursor: pointer;
          }
          @include respond(hover) {
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }

    // accordion open
    &__link {
      &.is-active {
        & .arrow {
          transform: rotate(225deg);
        }
      }
    }
  }

  // indexページ
  .header {
    &.header-2 {
      background-color: transparent;
      position: absolute;
      & .header__link {
        // color: $color-white;
        color: $color-black;
        transition: 0.3s ease;
        @include respond(hover) {
          &:hover {
            color: $color-primary;
            & .arrow {
              border-bottom: 1px solid $color-primary;
              border-right: 1px solid $color-primary;
            }
          }
        }
      }
      & .header__item {
        & ul {
          & li {
            background-color: transparent;
          }
        }
      }
      &.inview {
        position: fixed;
        background-color: $color-beige-1;
        animation: fadeIn 0.3s ease;
        & .header__link {
          color: $color-black;
          & .arrow {
            border-bottom: 1px solid $color-black;
            border-right: 1px solid $color-black;
          }
          @include respond(hover) {
            &:hover {
              color: $color-primary;
              & .arrow {
                border-bottom: 1px solid $color-primary;
                border-right: 1px solid $color-primary;
              }
            }
          }
        }
        & .header__item {
          & ul {
            & li {
              background-color: $color-beige-1;
            }
          }
        }
      }
    }
  }
}
