@charset "UTF-8";
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  animation: 0.8s fadeIn forwards;
  // sp font-size
  @include respond(sp) {
    // font-size: max(calc(10 / #{$sp-basis} * 100vw), 10px);
    font-size: calc(10 / #{$sp-basis} * 100vw);
     //chrome // iosとsafariはaタグで調整
    scroll-padding-top: 15rem;
  }
  // sp ~ pc font-size
  @include respond(tab-port) {
    @include pcmin('font-size', 10);
    scroll-padding-top: 11rem;
  }
  // pc or more
  @include respond(pc) {
    font-size: 62.5%;
  }
}
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $font-aotf-pr6n;
  width: 100%;
  color: #000;
  @include respond(sp) {
    font-size: 3.1rem;
    line-height: 1.87;
    letter-spacing: 0.05em;
  }
  @include respond(tab-port) {
    font-size: 1.6rem;
    line-height: 1.75;
    letter-spacing: 0.05em;
  }
  &.fixed {
    width: 100%;
    height: 100%;
    position: fixed;
  }
  &.overflow-hidden {
    overflow: hidden !important;
  }
}
.wrapper,
body {
  position: relative;
}

.wrapper {
  overflow: hidden;
}
a {
  text-decoration: none;
  color: $color-black;
  transition: 0.3s ease;
  &:hover {
    color: $color-gray;
  }
}

img {
  display: block;
}
// safari対策
/* autoprefixer: ignore next */
_::-webkit-full-page-media,
_:future,
:root html {
  min-height: -webkit-fill-available;
}
_::-webkit-full-page-media,
_:future,
:root body {
  min-height: -webkit-fill-available;
  min-height: 100vh;
}
