@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .case-2 {
    padding: 7.75rem 0 18.6rem;
    background-color: $color-beige-1;

    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      position: relative;
    }
    &__container {
    }

    &__heading-primary {
      font-family: $font-garamond;
      color: $color-primary-light-1;
      font-size: 8rem;
      letter-spacing: 0.05em;
      line-height: 1;
      white-space: nowrap;
      text-align: right;
      margin-right: 3.1rem;
      padding-bottom: 1rem;
      margin-bottom: 2.45rem;
    }
    &__heading-secondary {
      color: $color-primary;
      font-size: 2.8rem;
      line-height: 1.07;
      letter-spacing: 0.05em;
      text-align: right;
      margin-right: 3.1rem;
    }

    &__image {
      width: 59.7rem;
      height: 39.1rem;
      display: block;
    }
    &-item {
      &.swiper-slide-active.sp-inview {
        & .case-2__text-container {
          transform: translateY(0);
        }
      }
    }
    &__slide {
      position: relative;
      width: 59.7rem;
      height: 39.1rem;
      display: block;
      cursor: pointer;
      overflow: hidden;
    }
    &__text-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 59.7rem;
      height: 21.8rem;
      background-color: rgba($color-white, 0.9);
      padding: 0.98rem 1.31rem 1.89rem 2.51rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      transform: translateY(100%);
      &:hover {
        background-color: rgba($color-white, 1);
      }
    }
    &__number {
      font-family: $font-Montserrat;
      font-size: 3.6rem;
      line-height: 1;
      letter-spacing: 0.1em;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: $color-black;
    }
    &__case {
      font-family: $font-Montserrat;
      font-weight: 600;
      font-size: 2.1rem;
      line-height: 1;
      letter-spacing: 0.1em;
      margin-right: 0.7rem;
    }
    &__text-box {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      & span {
        align-self: flex-end;
      }
    }
    &__heading-tertiary {
      align-self: flex-start;
      font-family: $font-aotf-pro;
      font-size: 3.4rem;
      line-height: 1.47;
      letter-spacing: 0.08em;
      color: $color-black;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      @include line-clamp(2);
      & > span {
        width: 57.2rem;
        margin-right: 2rem;
      }
      & .arrow {
        margin-bottom: 1.4rem;
        margin-right: 0.5em;
        flex-shrink: 0;
      }
    }
    &__text {
      font-size: 2.3rem;
      font-family: $font-aotf-pro;
      line-height: 1.34;
      letter-spacing: 0.08em;
      margin-bottom: 2rem;
    }
  }
}
/* PC
*******************/
@include respond(tab-port) {
  .case-2 {
    padding: 0 0 25rem;
    background-color: $color-beige-1;

    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      position: relative;
    }
    &__container {
    }

    &__heading-primary {
      font-family: $font-garamond;
      color: $color-primary-light-1;
      font-size: 6.438rem;
      letter-spacing: 0.05em;
      white-space: nowrap;
      text-align: right;
      line-height: 1;
      margin-right: 4rem;
      padding-bottom: 1rem;
      margin-bottom: 1.25rem;
    }
    &__heading-secondary {
      color: $color-primary;
      line-height: 1.875;
      letter-spacing: 0.1em;
      text-align: right;
      margin-right: 4rem;
    }

    &__image {
      max-width: 63.7rem;
      width: 100%;
      height: auto;
      display: block;
    }
    &__slide {
      position: relative;
      max-width: 63.7rem;
      display: block;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        .case-2__text-container {
          transform: translateY(0);
        }
      }
    }
    &__text-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 63.7rem;
      height: 17.8rem;
      background-color: rgba($color-white, 0.9);
      padding: 0.98rem 1.31rem 1.89rem 2.51rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      transform: translateY(100%);
      &:hover {
        background-color: rgba($color-white, 1);
      }
    }
    &__number {
      font-family: $font-Montserrat;
      font-size: 2.666rem;
      line-height: 1;
      letter-spacing: 0.1em;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: $color-black;
    }
    &__case {
      font-family: $font-Montserrat;
      font-weight: 600;
      font-size: 1.212rem;
      line-height: 1;
      letter-spacing: 0.1em;
      margin-right: 0.7rem;
    }
    &__text-box {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      & span {
        align-self: flex-end;
      }
    }
    &__heading-tertiary {
      align-self: flex-start;
      font-family: $font-aotf-pro;
      font-size: 2.5rem;
      line-height: 1.8;
      letter-spacing: 0.18em;
      color: $color-black;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      @include line-clamp(1);
      & > span {
        width: 57.2rem;
      }
      & .arrow {
        margin-bottom: 1.4rem;
        margin-right: 0.5em;
      }
    }
    &__text {
      font-size: 1.6rem;
      font-family: $font-aotf-pro;
      line-height: 2.825;
      letter-spacing: 0.18em;
    }
  }
}
