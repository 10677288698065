@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .button {
    display: block;
    cursor: pointer;
    transition: 0.3s ease;
    &.white,
    &.beige {
      font-size: 3.6rem;
      letter-spacing: 0.03em;
      line-height: 1.8;
      text-align: center;
      color: $color-black;
      border: 1px solid $color-black;
      width: 46rem;
      height: 11rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.white {
      background: $color-white;
      @include respond(hover) {
        &:hover {
          color: $color-white;
          background: $color-black;
          border: 1px solid $color-black;

          & .arrow.right {
            border-top: solid 1px $color-white;
            border-right: solid 1px $color-white;
          }
        }
      }
    }
    &.beige {
      background: $color-beige-1;
      @include respond(hover) {
        &:hover {
          color: $color-beige-1;
          background: $color-black;
          border: 1px solid $color-black;

          & .arrow.right {
            border-top: solid 1px $color-beige-1;
            border-right: solid 1px $color-beige-1;
          }
        }
      }
    }
    &.form {
      font-size: 3.4rem;
      letter-spacing: 0.03em;
      line-height: 3.11;
      text-align: center;
      color: $color-white;
      background: $color-black;
      border: 1px solid $color-black;
      width: 46rem;
      height: 11rem;
      @include respond(hover) {
        &:hover {
          color: $color-black;
          background: $color-white;
          border: 1px solid $color-black;
          & .arrow {
            border-color: $color-black !important;
          }
        }
      }
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .button {
    display: block;
    cursor: pointer;
    transition: 0.3s ease;
    &.white {
      font-size: 1.7rem;
      letter-spacing: 0.1em;
      line-height: 2.7;
      text-align: center;
      color: $color-black;
      background: $color-white;
      border: 1px solid $color-black;
      width: 20rem;
      height: 4.8rem;
      &:hover {
        color: $color-white;
        background: $color-black;
        border: 1px solid $color-black;

        & .arrow.right {
          border-top: solid 1px $color-white;
          border-right: solid 1px $color-white;
        }
      }
    }
    &.form {
      font-size: 1.7rem;
      letter-spacing: 0.05em;
      line-height: 3.2;
      text-align: center;
      color: $color-white;
      background: $color-black;
      border: 1px solid $color-black;
      width: 25rem;
      height: 5.4rem;
      &:hover {
        color: $color-black;
        background: $color-white;
        border: 1px solid $color-black;
        & .arrow {
          border-color: $color-black !important;
        }
      }
    }
  }
}
