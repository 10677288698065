@charset "UTF-8";
$pattern: (
  'up': translateY(15px),
  'down': translateY(-150%),
  'left': translateX(150%),
  'right': translateX(-150%),
  'zoom-out': scale(1.1),
);

// クラスのアイテムの子要素をanimate
@each $key, $value in $pattern {
  .appear.#{$key} {
    & .item {
      transform: $value;
    }
  }
}

.appear {
  & .item {
    transition: opacity 1.2s $ease-out, transform 1.5s $ease-out;
    opacity: 0;
  }
  &.inview {
    & .item {
      opacity: 1;
      transform: none;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.1s;
          // @include respond(tab-port) {
          //   transition-delay: 0;
          // }
        }
      }

      &.centered {
        transform: translateX(-50%);
      }
    }
  }
  // sアニメーション(appear がinviewになったらその中のanimのs-itemがすべて反応する
  & .anim {
    & .s-item {
      transition: opacity 1.2s $ease-out, transform 1.5s $ease-out;
      opacity: 0;
      &.up {
        transform: translateY(15px);
      }
      &.center-up {
        transform: translate(-50%, 15px);
      }
      &.zoom-out {
        transform: scale(1.1);
      }
      &.center-zoom-out {
        transform: translateX(-50%) scale(1.1);
      }
      &.d-block {
        display: block;
      }
      &.d-grid {
        display: grid;
      }
      &.d-flex {
        display: flex;
      }
      &.opacity {
        opacity: 0;
      }
    }
  }
  &.inview {
    & .anim .s-item {
      opacity: 1;
      transform: none;
      &.center-up,
      &.center-zoom-out {
        opacity: 1;
        transform: translateX(-50%);
      }
    }
  }
}

// クラスのアイテムをanimate
@each $key, $value in $pattern {
  .appear-this-item.#{$key} {
    transform: $value;
  }
}
.appear-this-item {
  transition: opacity 1.2s $ease-out, transform 1.5s $ease-out;
  opacity: 0;
  &.duration2 {
    transition-duration: 2s;
  }
  &.duration4 {
    transition-duration: 4s;
  }
  &.inview {
    opacity: 1 !important;
    transform: none;
    &.centered {
      transform: translateX(-50%);
    }
  }
}

// delay
@for $i from 1 through 20 {
  .delay#{$i} {
    transition-delay: $i * 0.1s !important;
    // @include respond(tab-port) {
    //   // transition-delay: 0s;
    // }
  }
}

.appear.serials {
  & .item {
    transition: opacity 1.2s $ease-out, transform 1.5s $ease-out;
    opacity: 0;
  }
  &.inview {
    & .item {
      opacity: 1;
      transform: none;

      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.1s;
          // @include respond(tab-port) {
          //   transition-delay: 0;
          // }
        }
      }
      &.centered {
        transform: translateX(-50%);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1 !important;
    visibility: visible;
  }
  100% {
    opacity: 0 !important;
    visibility: hidden;
  }
}

@keyframes showDown {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes hideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-150%);
  }
}

@keyframes name-up {
  0% {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 85;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
