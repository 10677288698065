// COLORS
$color-primary: #968c6a; //gold
$color-primary-light-1: #8a805f;
$color-primary-light-2: #958464;
$color-primary-dark: #857c5e; //gold_2

$color-primary-light-3: #ccbe91;
// #b3a77f
// #857c5e
// #968c6a

$color-beige-1: #efeee8;
$color-beige-2: #dfd6bb;
$color-beige-3: #f6f6f3;

$color-line: #afafaf;

$color-gray: #727272; //gray
$color-gray-2: #999999; //gray_2

$color-gray-1: #808080;
$color-gr3y-3: #272727;

$color-red: #c70000;
$color-white: #fff;
$color-black: #000;


// FONT FAMILY
$font-garamond: 'EB Garamond', serif;
$font-jost: 'Jost', sans-serif;
// A-OTF Gothic BBB Pr6N - body main - 下と同じでOK
$font-aotf-pr6n: 'Gothic Medium BBB', sans-serif;
// // A-OTF Gothic BBB Pro - footer -
$font-aotf-pro: 'Gothic Medium BBB', sans-serif;
// BasicGothicPro-Book は Khulaに変更
$font-Khula: 'Khula', sans-serif;
// Montserrat-SemiBold = 600
// Montserrat-Medium = 400
$font-Montserrat: 'Montserrat', sans-serif;

// CONTAINER
$container-width: 144rem;
$content-width: 100rem;
// $content-padding: 5rem;
$title-padding: 12.7rem;

// HEADER HEIGHT
$header-height: 8.4rem;
$header-height-sp: 13.3rem;
// $top-news-height: 6rem;
// $header-height-pc: 8rem;

// cubic-bezier
$ease-out: cubic-bezier(0.170, 0.935, 0.305, 1.000);

// CANVAS SIZE
$big-pc: 1920;
$pc-basis: 1440;
$sp-basis: 750;
$small-phone-basis: 320;
$pc-height: 900;
$sp-height: 1500; //(750px base)
