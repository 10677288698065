@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .footer {
    width: 100%;
    background-color: #dfd6bb;
    font-family: $font-aotf-pro;

    &__container {
      max-width: 144rem;
      margin: 0 auto;
      padding: 12rem 5.8rem 4.6rem 5.8rem;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    &__left {
      padding: 2.8rem 0;
    }
    &__company-name {
      font-feature-settings: 'palt';
      font-size: 3.4rem;
      letter-spacing: 0.05em;
      line-height: 1;
      margin-bottom: 3.9rem;
    }
    &__company-details {
      font-feature-settings: 'palt';
      font-size: 2.6rem;
      letter-spacing: 0.05em;
      line-height: 1.69;
      margin-bottom: 7.1rem;
    }
    &__list {
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 4.25rem;
    }
    &__link {
      line-height: 1.48;
      display: inline-block;
      padding-bottom: 1.25rem;
      border-bottom: 1.5px solid $color-black;
    }
    &__copy {
      margin-top: 10.55rem;
      font-size: 2rem;
      line-height: 2.2;
      display: flex;
      align-items: center;
      & span {
        font-size: 3.5rem;
        line-height: 1;
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }
}
/* PC
*******************/
@include respond(tab-port) {
  .footer {
    width: 100%;
    background-color: #dfd6bb;
    font-family: $font-aotf-pro;

    &__container {
      max-width: 144rem;
      margin: 0 auto;
      padding: 3.4rem 1.2rem 1.2rem 3.75rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: end;
      align-items: flex-end;
    }
    &__left {
      padding: 2.8rem 0;
    }
    &__company-details,
    &__company-name {
      font-size: 1.3rem;
      line-height: 1.53;
      letter-spacing: 0.05em;
      font-feature-settings: 'palt';
    }
    &__company-name {
      margin-bottom: 1.4rem;
    }

    &__list {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
    }
    &__link {
      font-size: 1.5rem;
      line-height: 1.6;
      display: block;
      padding: 2.3rem;
    }
    &__copy {
      display: none;
    }
  }
}
