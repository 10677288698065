@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .swiper {
    &__top {
      // pagination position
      & .swiper-pagination {
        text-align: right;
      }
      // pagination position
      & .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 24px;
        left: auto;
        right: 4rem;
      }
      // pagination bullets
      & .swiper-pagination-bullet {
        width: 6rem;
        height: 6px;
        background: rgba($color-white, 0.5);
        border-radius: unset;
        &-active {
          background: $color-white;
        }
      }
    }
    &__case-2 {
      width: 100%;
      height: 39.1rem;
      .swiper-wrapper {
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        visibility: visible;
        transition-timing-function: ease-out;
      }
      .swiper-slide {
        min-width: 59.7rem;
        width: 59.7rem !important;
        margin-right: 2rem;
      }
    }
    &__column {
      width: 100%;
      height: 72rem;
      margin-left: 5.8rem !important;

      .swiper-wrapper {
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        visibility: visible;
        transition-timing-function: ease-out;
      }
      .swiper-slide {
        min-width: 50rem;
        width: 50rem !important;
        margin-right: 3.6rem;
      }
    }
  }

  // Case
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }
  .swiper-button-prev__case-2,
  .swiper-button-next__case-2 {
    display: none;
  }

  // column
  .swiper-button-prev__column,
  .swiper-button-next__column {
    top: 15.4rem !important;
  }
  .swiper-button-prev__column {
    right: 9rem !important;
    left: auto !important;
  }
  .swiper-button-next__column {
    right: 4rem !important;
  }
}
/* PC
*******************/
@include respond(tab-port) {
  .swiper {
    &__top {
      // pagination position
      & .swiper-pagination {
        text-align: right;
      }
      // pagination position
      & .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 24px;
        left: auto;
        right: 4rem;
      }
      // pagination bullets
      & .swiper-pagination-bullet {
        width: 6rem;
        height: 6px;
        background: rgba($color-white, 0.5);
        border-radius: unset;
        &-active {
          background: $color-white;
        }
      }
    }
    &__case-2 {
      width: 100%;
      height: 41.8rem;

      .swiper-wrapper {
        transition-timing-function: ease-out;
      }
      .swiper-slide {
        min-width: 63.7rem;
        width: 63.7rem !important;
      }
    }
    &__column {
      width: 100%;
      height: 41.193rem;

      .swiper-wrapper {
        transition-timing-function: ease-out;
      }
      .swiper-slide {
        min-width: 36.536rem;
        width: 36.536rem !important;
        margin-right: 2.63rem;
      }
    }
  }

  // Case
  .swiper-button-prev,
  .swiper-button-next {
    width: 4rem !important;
    height: 4rem !important;
    border-radius: 50%;
    border: 1px solid $color-primary-light-2;
    z-index: 1 !important;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.5rem !important;
    color: $color-primary-light-2;
  }
  .swiper-button-prev__case-2,
  .swiper-button-next__case-2 {
    top: auto !important;
    bottom: -7rem;
  }
  .swiper-button-prev__case-2 {
    right: 9rem !important;
    left: auto !important;
  }
  .swiper-button-next__case-2 {
    right: 4rem !important;
  }

  // column
  .swiper-button-prev__column,
  .swiper-button-next__column {
    top: 16.6rem !important;
  }
  .swiper-button-prev__column {
    right: 9rem !important;
    left: auto !important;
  }
  .swiper-button-next__column {
    right: 4rem !important;
  }
}
