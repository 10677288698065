@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .arrow {
    display: inline-block;
    transition: 0.3s ease;
    width: 1.4rem;
    height: 1.4rem;
    border-width: 0.15rem;
    &.down {
      transform: translateY(-3px) rotate(45deg);
      border-bottom: solid 0.15rem $color-black;
      border-right: solid 0.15rem $color-black;

      &.white {
        border-bottom: solid 0.15rem $color-white;
        border-right: solid 0.15rem $color-white;
      }
    }
    &.up {
      border-top: solid 0.15rem $color-black;
      border-left: solid 0.15rem $color-black;
      transform: rotate(45deg);
      &.white {
        border-top: solid 0.15rem $color-white;
        border-left: solid 0.15rem $color-white;
      }
    }
    &.right {
      border-top: solid 0.15rem $color-black;
      border-right: solid 0.15rem $color-black;
      transform: rotate(45deg);
      &.white {
        border-top: solid 0.15rem $color-white;
        border-right: solid 0.15rem $color-white;
      }
      &.large {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    &.left {
      border-bottom: solid 0.15rem $color-black;
      border-left: solid 0.15rem $color-black;
      // margin-top: 16px;/*なくてもOK*/
      transform: rotate(45deg);
      &.white {
        border-bottom: solid 0.15rem $color-white;
        border-left: solid 0.15rem $color-white;
      }
    }
  }
}
/* PC
*******************/
@include respond(tab-port) {
  .arrow {
    display: inline-block;
    transition: 0.3s ease;
    width: 0.9rem;
    height: 0.9rem;

    &.down {
      transform: translateY(-3px) rotate(45deg);
      border-bottom: solid 1px $color-black;
      border-right: solid 1px $color-black;

      &.white {
        border-bottom: solid 1px $color-white;
        border-right: solid 1px $color-white;
      }
    }
    &.up {
      border-top: solid 1px $color-black;
      border-left: solid 1px $color-black;
      transform: rotate(45deg);
      &.white {
        border-top: solid 1px $color-white;
        border-left: solid 1px $color-white;
      }
    }
    &.right {
      border-top: solid 1px $color-black;
      border-right: solid 1px $color-black;
      transform: rotate(45deg);
      &.white {
        border-top: solid 1px $color-white;
        border-right: solid 1px $color-white;
      }
      &.large {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    &.left {
      border-bottom: solid 1px $color-black;
      border-left: solid 1px $color-black;
      // margin-top: 16px;/*なくてもOK*/
      transform: rotate(45deg);
      &.white {
        border-bottom: solid 1px $color-white;
        border-left: solid 1px $color-white;
      }
    }
  }
}
