@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .menu {
    cursor: pointer;
    width: 7.4rem;
    height: 3.6rem;
    &__wrapper {
      pointer-events: none;
      position: relative;

    }
    & span {
      pointer-events: none;
      display: block;
      width: 7.4rem;
      height: 2px;
      background-color: $color-black;
      position: absolute;
      left: 0;
      transition: 0.5s ease;
      &:first-child {
        top: 0;
      }
      &:nth-child(2) {
        top: 1.53rem;
      }
      &:nth-child(3) {
        top: 3.05rem;
      }
    }
    &.open {
      & span:first-child {
        @include respond(sp) {
          transform: translate(0, 1.5rem) rotate(45deg);
        }
      }
      & span:nth-child(2) {
        transform: scaleX(0);
      }
      & span:nth-child(3) {
        @include respond(sp) {
          transform: translate(0, -1.5rem) rotate(-45deg);
        }
      }
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .menu {
    display: none;
  }
}
