@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .column {
    background-color: $color-beige-1;

    &__wrapper {
      position: relative;
    }
    &__container {
      background-color: $color-white;
      width: 100%;
      padding-top: 7.61rem;
      padding-bottom: 10.2rem;
    }

    &__heading-primary {
      font-family: $font-garamond;
      color: $color-primary-light-1;
      font-size: 8rem;
      margin-bottom: 2.15rem;
      white-space: nowrap;
      text-align: left;
      line-height: 1;
      letter-spacing: 0.05em;
      padding-left: 5.8rem;
    }
    &__heading-secondary {
      color: $color-primary;
      font-size: 2.8rem;
      line-height: 1.07;
      letter-spacing: 0.05em;
      text-align: left;
      padding-left: 5.8rem;
    }

    &__image {
      width: 50rem;
      height: 32.4rem;
      object-fit: cover;
      display: block;
      transition: 0.3s ease-in-out;
    }
    &__slider-container {
      position: relative;
      width: 100%;
      width: 50rem;
      display: block;
      cursor: pointer;
      overflow: hidden;
    }
    &__image-container {
      overflow: hidden;
    }
    &__text-container {
      width: 50rem;
      height: 39.5rem;
      background-color: $color-beige-3;
      padding: 4.86rem 2.7rem 2.5rem 2.7rem;
      display: flex;
      flex-direction: column;
    }
    &__text-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: 0;
      align-items: flex-end;
      width: 100%;
    }
    &__text {
      color: $color-primary-light-1;
      font-size: 2.3rem;
      letter-spacing: 0.1em;
      line-height: 1.34;
    }
    &__text-2 {
      font-size: 3.1rem;
      letter-spacing: 0.08em;
      line-height: 1;
      & .arrow {
        align-self: flex-end;
        margin-bottom: 0.4rem;
        margin-left: -4px;
      }
    }
    &__button-container {
      margin-top: 7.34rem;
      & .arrow {
        transform: translateX(3rem) rotate(45deg);
      }
      & .button {
        margin: 0 auto;
        font-size: 3.4rem;
        line-height: 1.9;
      }
    }

    &__heading-tertiary {
      align-self: flex-start;
      font-family: $font-aotf-pro;
      font-size: 3.4rem;
      line-height: 1.47;
      letter-spacing: 0.08em;
      color: $color-black;
      @include line-clamp(3);
      & .case-2__text {
        font-size: 1.6rem;
        font-family: $font-aotf-pro;
        line-height: 2.825;
        letter-spacing: 0.18em;
      }
    }
    &__date {
      color: $color-primary-light-1;
      font-size: 2.3rem;
      letter-spacing: 0.03em;
      line-height: 1.34;
      margin-bottom: 3.7rem;
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .column {
    padding-left: 9rem;
    background-color: $color-beige-1;

    &__wrapper {
      max-width: calc(#{$container-width} - 9rem); //padding-leftの分引く
      margin: 0 auto;
      position: relative;
    }
    &__container {
      background-color: $color-white;
      width: 100%;
      padding-top: 8.41rem;
      padding-left: 10.96rem;
      padding-bottom: 7.13rem;
    }

    &__heading-primary {
      font-family: $font-garamond;
      color: $color-primary-light-1;
      font-size: 6.4rem;
      padding-bottom: 1rem;
      margin-bottom: 2.15rem;
      white-space: nowrap;
      text-align: left;
      line-height: 1;
      letter-spacing: 0.05em;
    }
    &__heading-secondary {
      color: $color-primary;
      line-height: 1.875;
      letter-spacing: 0.1em;
      text-align: left;
    }

    &__image {
      width: 36.536rem;
      height: 23.6rem;
      object-fit: cover;
      display: block;
      transition: 0.3s ease-in-out;
    }
    &__slider-container {
      position: relative;
      width: 100%;
      max-width: 36.536rem;
      display: block;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        .column__image {
          transform: scale(1.1);
        }
      }
    }
    &__image-container {
      overflow: hidden;
    }
    &__text-container {
      width: 100%;
      max-width: 36.536rem;
      height: 17.8rem;
      background-color: $color-beige-3;
      padding: 2rem 2.09rem 2rem 2.14rem;
      display: flex;
      flex-direction: column;
    }
    &__text-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: 0;
      align-items: flex-end;
      width: 100%;
    }
    &__text {
      color: $color-primary-light-1;
      font-size: 1.4rem;
      letter-spacing: 0.1em;
      line-height: 1.75;
    }
    &__text-2 {
      font-size: 1.7rem;
      letter-spacing: 0.1em;
      line-height: 1.41;
      & span {
        align-self: flex-end;
        margin-bottom: 1px;
        margin-left: -4px;
      }
      &.link {
        display: block;
        text-align: end;
        margin-right: 4rem;
        margin-top: 4.45rem;
      }
    }
    &__heading-tertiary {
      align-self: flex-start;
      font-family: $font-aotf-pro;
      font-size: 1.7rem;
      line-height: 1.53;
      letter-spacing: 0.06em;
      color: $color-black;
      @include line-clamp(2);
      & .case-2__text {
        font-size: 1.6rem;
        font-family: $font-aotf-pro;
        line-height: 2.825;
        letter-spacing: 0.18em;
      }
    }
    &__date {
      color: $color-primary-light-1;
      font-size: 1.4rem;
      letter-spacing: 0.03em;
      line-height: 1.75;
      margin-bottom: 1rem;
    }
  }
}
