@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .oem-1 {
    padding: 21.2rem 0 0;
    background-color: $color-beige-1;
    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      position: relative;
    }
    &__container {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
    }
    &__text-container {
      z-index: 10;
      background-color: $color-white;
      padding-left: 8.5rem;
      padding-right: 8.5rem;
      padding-top: 22rem;
      padding-bottom: 12rem;
      margin-top: -10.6rem;
    }
    &__heading-primary {
      color: $color-primary;
      font-size: 13.5rem;
      letter-spacing: 0.06em;
      line-height: 0.7;
      padding-left: 0.5rem;
      font-family: $font-garamond;
      writing-mode: vertical-rl;
      z-index: 100;
      position: absolute;
      top: -11.7rem;
      left: 3rem;
    }
    &__heading-secondary {
      font-family: $font-aotf-pro;
      font-size: 4.8rem;
      line-height: 1;
      letter-spacing: 0.05em;
      margin-bottom: 6.8rem;
      font-feature-settings: 'palt';
      text-align: center;
    }
    &__text {
      font-size: 3.1rem;
      line-height: 1.87;
      letter-spacing: 0.08em;
      // font-feature-settings: "palt";
    }
    &__image-container {
      position: relative;
      z-index: 20;
      justify-self: end;
      margin-left: 5.8rem;
    }
    &__image-1 {
      object-position: left;
      width: 100%;
      height: 51rem;
      object-fit: cover;
    }
    &__button-container {
      margin-top: 8rem;
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .oem-1 {
    padding: 18rem 0 8.8rem;
    background-color: $color-beige-1;
    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      position: relative;
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 29rem;
      padding-bottom: 15.9rem;
      position: relative;
    }
    &__text-container {
      position: absolute;
      z-index: 50;
      max-width: 72.4rem;
      background-color: $color-white;
      padding-left: 10rem;
      padding-right: 12.6rem;
      padding-top: 8.6rem;
      padding-bottom: 6.6rem;
      bottom: 0;
      right: 0;
    }
    &__heading-primary {
      grid-column: 2 / span 1;
      grid-row: 1;
      color: $color-primary;
      font-size: 12rem;
      line-height: 1;
      letter-spacing: 0.05em;
      font-family: $font-garamond;
      writing-mode: vertical-rl;
      max-width: 7.24rem;
      position: absolute;
      right: 4.9rem;
      top: 25.5rem;
      z-index: 100;
      align-self: flex-end;
    }
    &__heading-secondary {
      font-family: $font-aotf-pro;
      font-size: 2.9rem;
      line-height: 2;
      letter-spacing: 0.05em;
      margin-bottom: 4.4rem;
    }
    &__text {
      line-height: 1.875;
      letter-spacing: 0.05em;
      max-width: 46.8rem;
      // font-feature-settings: "palt";
    }
    &__image-container {
      grid-column: 1 / span 1;
      grid-row: 1;
      position: relative;
      z-index: 20;
      max-width: 115rem;
      justify-self: start;
    }
    &__image-1 {
      min-height: 70rem; //要調整
      object-fit: cover;
      object-position: right;
      width: 100%;
      height: auto;
    }
    &__button-container {
      margin-top: 3rem;
    }
  }
}
