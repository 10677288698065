@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .top {
    width: 100%;
    height: 80rem;
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    z-index: 1;
    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }

    &__container-1,
    &__container-2,
    &__container-3 {
      width: 100%;
      height: 100%;
    }
    &__container-1 {
      background: linear-gradient(rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)),
        url('../images/index/top-1@sp.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &__container-2 {
      background: linear-gradient(rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)),
        url('../images/index/top-2@sp.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &__container-3 {
      background: linear-gradient(rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)),
        url('../images/index/top-3@sp.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &__text {
      position: absolute;
      z-index: 10;
      bottom: 10rem;
      left: 4rem;
      font-family: $font-aotf-pro;
      font-size: 4.6rem;
      line-height: 1.95;
      letter-spacing: 0.05em;
      color: $color-white;
      filter: drop-shadow(0 0 3px $color-black);
    }

    & .swiper-pagination {
      display: none;
    }
  }
}
/* PC
*******************/
@include respond(tab-port) {
  .top {
    width: 100%;
    height: 80rem;
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    z-index: 1;
    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }

    &__container-1,
    &__container-2,
    &__container-3 {
      width: 100%;
      height: 100%;
    }
    &__container-1 {
      background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
        url('../images/index/top-1@2x.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &__container-2 {
      background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
        url('../images/index/top-2@2x.jpg');

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &__container-3 {
      background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
        url('../images/index/top-3@2x.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &__text {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding-left: 7.2rem;
      font-family: $font-aotf-pro;
      font-size: 2.8rem;
      line-height: 1.66;
      letter-spacing: 0.12em;
      color: $color-white;
      filter: drop-shadow(0 0 3px $color-black);
    }

    & .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0 7px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
