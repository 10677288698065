/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, input, select, textarea {
  background-color: transparent;
  outline: none;
  border: 0;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

/* vw for sp
============================================================= */
/* vw to rem for small-phone
============================================================= */
/* vw for pc
============================================================= */
/* vw to rem for big-pc
============================================================= */
/* ROUND
============================================================= */
.appear.up .item {
  transform: translateY(15px);
}

.appear.down .item {
  transform: translateY(-150%);
}

.appear.left .item {
  transform: translateX(150%);
}

.appear.right .item {
  transform: translateX(-150%);
}

.appear.zoom-out .item {
  transform: scale(1.1);
}

.appear .item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear.inview .item {
  opacity: 1;
  transform: none;
}
.appear.inview .item:nth-child(1) {
  transition-delay: 0.1s;
}
.appear.inview .item:nth-child(2) {
  transition-delay: 0.2s;
}
.appear.inview .item:nth-child(3) {
  transition-delay: 0.3s;
}
.appear.inview .item:nth-child(4) {
  transition-delay: 0.4s;
}
.appear.inview .item:nth-child(5) {
  transition-delay: 0.5s;
}
.appear.inview .item:nth-child(6) {
  transition-delay: 0.6s;
}
.appear.inview .item:nth-child(7) {
  transition-delay: 0.7s;
}
.appear.inview .item:nth-child(8) {
  transition-delay: 0.8s;
}
.appear.inview .item:nth-child(9) {
  transition-delay: 0.9s;
}
.appear.inview .item:nth-child(10) {
  transition-delay: 1s;
}
.appear.inview .item.centered {
  transform: translateX(-50%);
}
.appear .anim .s-item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear .anim .s-item.up {
  transform: translateY(15px);
}
.appear .anim .s-item.center-up {
  transform: translate(-50%, 15px);
}
.appear .anim .s-item.zoom-out {
  transform: scale(1.1);
}
.appear .anim .s-item.center-zoom-out {
  transform: translateX(-50%) scale(1.1);
}
.appear .anim .s-item.d-block {
  display: block;
}
.appear .anim .s-item.d-grid {
  display: grid;
}
.appear .anim .s-item.d-flex {
  display: flex;
}
.appear .anim .s-item.opacity {
  opacity: 0;
}
.appear.inview .anim .s-item {
  opacity: 1;
  transform: none;
}
.appear.inview .anim .s-item.center-up, .appear.inview .anim .s-item.center-zoom-out {
  opacity: 1;
  transform: translateX(-50%);
}

.appear-this-item.up {
  transform: translateY(15px);
}

.appear-this-item.down {
  transform: translateY(-150%);
}

.appear-this-item.left {
  transform: translateX(150%);
}

.appear-this-item.right {
  transform: translateX(-150%);
}

.appear-this-item.zoom-out {
  transform: scale(1.1);
}

.appear-this-item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear-this-item.duration2 {
  transition-duration: 2s;
}
.appear-this-item.duration4 {
  transition-duration: 4s;
}
.appear-this-item.inview {
  opacity: 1 !important;
  transform: none;
}
.appear-this-item.inview.centered {
  transform: translateX(-50%);
}

.delay1 {
  transition-delay: 0.1s !important;
}

.delay2 {
  transition-delay: 0.2s !important;
}

.delay3 {
  transition-delay: 0.3s !important;
}

.delay4 {
  transition-delay: 0.4s !important;
}

.delay5 {
  transition-delay: 0.5s !important;
}

.delay6 {
  transition-delay: 0.6s !important;
}

.delay7 {
  transition-delay: 0.7s !important;
}

.delay8 {
  transition-delay: 0.8s !important;
}

.delay9 {
  transition-delay: 0.9s !important;
}

.delay10 {
  transition-delay: 1s !important;
}

.delay11 {
  transition-delay: 1.1s !important;
}

.delay12 {
  transition-delay: 1.2s !important;
}

.delay13 {
  transition-delay: 1.3s !important;
}

.delay14 {
  transition-delay: 1.4s !important;
}

.delay15 {
  transition-delay: 1.5s !important;
}

.delay16 {
  transition-delay: 1.6s !important;
}

.delay17 {
  transition-delay: 1.7s !important;
}

.delay18 {
  transition-delay: 1.8s !important;
}

.delay19 {
  transition-delay: 1.9s !important;
}

.delay20 {
  transition-delay: 2s !important;
}

.appear.serials .item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear.serials.inview .item {
  opacity: 1;
  transform: none;
}
.appear.serials.inview .item:nth-child(1) {
  transition-delay: 0.1s;
}
.appear.serials.inview .item:nth-child(2) {
  transition-delay: 0.2s;
}
.appear.serials.inview .item:nth-child(3) {
  transition-delay: 0.3s;
}
.appear.serials.inview .item:nth-child(4) {
  transition-delay: 0.4s;
}
.appear.serials.inview .item:nth-child(5) {
  transition-delay: 0.5s;
}
.appear.serials.inview .item:nth-child(6) {
  transition-delay: 0.6s;
}
.appear.serials.inview .item:nth-child(7) {
  transition-delay: 0.7s;
}
.appear.serials.inview .item:nth-child(8) {
  transition-delay: 0.8s;
}
.appear.serials.inview .item:nth-child(9) {
  transition-delay: 0.9s;
}
.appear.serials.inview .item:nth-child(10) {
  transition-delay: 1s;
}
.appear.serials.inview .item:nth-child(11) {
  transition-delay: 1.1s;
}
.appear.serials.inview .item:nth-child(12) {
  transition-delay: 1.2s;
}
.appear.serials.inview .item:nth-child(13) {
  transition-delay: 1.3s;
}
.appear.serials.inview .item:nth-child(14) {
  transition-delay: 1.4s;
}
.appear.serials.inview .item:nth-child(15) {
  transition-delay: 1.5s;
}
.appear.serials.inview .item:nth-child(16) {
  transition-delay: 1.6s;
}
.appear.serials.inview .item:nth-child(17) {
  transition-delay: 1.7s;
}
.appear.serials.inview .item:nth-child(18) {
  transition-delay: 1.8s;
}
.appear.serials.inview .item:nth-child(19) {
  transition-delay: 1.9s;
}
.appear.serials.inview .item:nth-child(20) {
  transition-delay: 2s;
}
.appear.serials.inview .item.centered {
  transform: translateX(-50%);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1 !important;
    visibility: visible;
  }
  100% {
    opacity: 0 !important;
    visibility: hidden;
  }
}
@keyframes showDown {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes hideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-150%);
  }
}
@keyframes name-up {
  0% {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 85;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  animation: 0.8s fadeIn forwards;
}
@media only screen and (max-width: 767px) {
  html {
    font-size: calc(10 / 750 * 100vw);
    scroll-padding-top: 15rem;
  }
}
@media only screen and (min-width: 768px) {
  html {
    font-size: min(0.69vw, 1rem);
    scroll-padding-top: 11rem;
  }
}
@media only screen and (min-width: 90.0625em) {
  html {
    font-size: 62.5%;
  }
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Gothic Medium BBB", sans-serif;
  width: 100%;
  color: #000;
}
@media only screen and (max-width: 767px) {
  body {
    font-size: 3.1rem;
    line-height: 1.87;
    letter-spacing: 0.05em;
  }
}
@media only screen and (min-width: 768px) {
  body {
    font-size: 1.6rem;
    line-height: 1.75;
    letter-spacing: 0.05em;
  }
}
body.fixed {
  width: 100%;
  height: 100%;
  position: fixed;
}
body.overflow-hidden {
  overflow: hidden !important;
}

.wrapper,
body {
  position: relative;
}

.wrapper {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #000;
  transition: 0.3s ease;
}
a:hover {
  color: #727272;
}

img {
  display: block;
}

/* autoprefixer: ignore next */
_::-webkit-full-page-media,
_:future,
:root html {
  min-height: -webkit-fill-available;
}

_::-webkit-full-page-media,
_:future,
:root body {
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

.center {
  margin: 0 auto !important;
}

@media only screen and (max-width: 767px) {
  .u-sp-none {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .u-pc-none {
    display: none !important;
  }
}

.u-sp-mb-43 {
  display: block;
}
@media only screen and (max-width: 767px) {
  .u-sp-mb-43 {
    margin-bottom: 4.3rem;
  }
}

/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .menu {
    cursor: pointer;
    width: 7.4rem;
    height: 3.6rem;
  }
  .menu__wrapper {
    pointer-events: none;
    position: relative;
  }
  .menu span {
    pointer-events: none;
    display: block;
    width: 7.4rem;
    height: 2px;
    background-color: #000;
    position: absolute;
    left: 0;
    transition: 0.5s ease;
  }
  .menu span:first-child {
    top: 0;
  }
  .menu span:nth-child(2) {
    top: 1.53rem;
  }
  .menu span:nth-child(3) {
    top: 3.05rem;
  }
}
@media only screen and (max-width: 767px) and (max-width: 767px) {
  .menu.open span:first-child {
    transform: translate(0, 1.5rem) rotate(45deg);
  }
}
@media only screen and (max-width: 767px) {
  .menu.open span:nth-child(2) {
    transform: scaleX(0);
  }
}
@media only screen and (max-width: 767px) and (max-width: 767px) {
  .menu.open span:nth-child(3) {
    transform: translate(0, -1.5rem) rotate(-45deg);
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .menu {
    display: none;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .button {
    display: block;
    cursor: pointer;
    transition: 0.3s ease;
  }
  .button.white, .button.beige {
    font-size: 3.6rem;
    letter-spacing: 0.03em;
    line-height: 1.8;
    text-align: center;
    color: #000;
    border: 1px solid #000;
    width: 46rem;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button.white {
    background: #fff;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .button.white:hover {
    color: #fff;
    background: #000;
    border: 1px solid #000;
  }
  .button.white:hover .arrow.right {
    border-top: solid 1px #fff;
    border-right: solid 1px #fff;
  }
}
@media only screen and (max-width: 767px) {
  .button.beige {
    background: #efeee8;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .button.beige:hover {
    color: #efeee8;
    background: #000;
    border: 1px solid #000;
  }
  .button.beige:hover .arrow.right {
    border-top: solid 1px #efeee8;
    border-right: solid 1px #efeee8;
  }
}
@media only screen and (max-width: 767px) {
  .button.form {
    font-size: 3.4rem;
    letter-spacing: 0.03em;
    line-height: 3.11;
    text-align: center;
    color: #fff;
    background: #000;
    border: 1px solid #000;
    width: 46rem;
    height: 11rem;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .button.form:hover {
    color: #000;
    background: #fff;
    border: 1px solid #000;
  }
  .button.form:hover .arrow {
    border-color: #000 !important;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .button {
    display: block;
    cursor: pointer;
    transition: 0.3s ease;
  }
  .button.white {
    font-size: 1.7rem;
    letter-spacing: 0.1em;
    line-height: 2.7;
    text-align: center;
    color: #000;
    background: #fff;
    border: 1px solid #000;
    width: 20rem;
    height: 4.8rem;
  }
  .button.white:hover {
    color: #fff;
    background: #000;
    border: 1px solid #000;
  }
  .button.white:hover .arrow.right {
    border-top: solid 1px #fff;
    border-right: solid 1px #fff;
  }
  .button.form {
    font-size: 1.7rem;
    letter-spacing: 0.05em;
    line-height: 3.2;
    text-align: center;
    color: #fff;
    background: #000;
    border: 1px solid #000;
    width: 25rem;
    height: 5.4rem;
  }
  .button.form:hover {
    color: #000;
    background: #fff;
    border: 1px solid #000;
  }
  .button.form:hover .arrow {
    border-color: #000 !important;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .arrow {
    display: inline-block;
    transition: 0.3s ease;
    width: 1.4rem;
    height: 1.4rem;
    border-width: 0.15rem;
  }
  .arrow.down {
    transform: translateY(-3px) rotate(45deg);
    border-bottom: solid 0.15rem #000;
    border-right: solid 0.15rem #000;
  }
  .arrow.down.white {
    border-bottom: solid 0.15rem #fff;
    border-right: solid 0.15rem #fff;
  }
  .arrow.up {
    border-top: solid 0.15rem #000;
    border-left: solid 0.15rem #000;
    transform: rotate(45deg);
  }
  .arrow.up.white {
    border-top: solid 0.15rem #fff;
    border-left: solid 0.15rem #fff;
  }
  .arrow.right {
    border-top: solid 0.15rem #000;
    border-right: solid 0.15rem #000;
    transform: rotate(45deg);
  }
  .arrow.right.white {
    border-top: solid 0.15rem #fff;
    border-right: solid 0.15rem #fff;
  }
  .arrow.right.large {
    width: 1.3rem;
    height: 1.3rem;
  }
  .arrow.left {
    border-bottom: solid 0.15rem #000;
    border-left: solid 0.15rem #000;
    transform: rotate(45deg);
  }
  .arrow.left.white {
    border-bottom: solid 0.15rem #fff;
    border-left: solid 0.15rem #fff;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .arrow {
    display: inline-block;
    transition: 0.3s ease;
    width: 0.9rem;
    height: 0.9rem;
  }
  .arrow.down {
    transform: translateY(-3px) rotate(45deg);
    border-bottom: solid 1px #000;
    border-right: solid 1px #000;
  }
  .arrow.down.white {
    border-bottom: solid 1px #fff;
    border-right: solid 1px #fff;
  }
  .arrow.up {
    border-top: solid 1px #000;
    border-left: solid 1px #000;
    transform: rotate(45deg);
  }
  .arrow.up.white {
    border-top: solid 1px #fff;
    border-left: solid 1px #fff;
  }
  .arrow.right {
    border-top: solid 1px #000;
    border-right: solid 1px #000;
    transform: rotate(45deg);
  }
  .arrow.right.white {
    border-top: solid 1px #fff;
    border-right: solid 1px #fff;
  }
  .arrow.right.large {
    width: 1.3rem;
    height: 1.3rem;
  }
  .arrow.left {
    border-bottom: solid 1px #000;
    border-left: solid 1px #000;
    transform: rotate(45deg);
  }
  .arrow.left.white {
    border-bottom: solid 1px #fff;
    border-left: solid 1px #fff;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .swiper__top .swiper-pagination {
    text-align: right;
  }
  .swiper__top .swiper-horizontal > .swiper-pagination-bullets,
.swiper__top .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 24px;
    left: auto;
    right: 4rem;
  }
  .swiper__top .swiper-pagination-bullet {
    width: 6rem;
    height: 6px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: unset;
  }
  .swiper__top .swiper-pagination-bullet-active {
    background: #fff;
  }
  .swiper__case-2 {
    width: 100%;
    height: 39.1rem;
  }
  .swiper__case-2 .swiper-wrapper {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    visibility: visible;
    transition-timing-function: ease-out;
  }
  .swiper__case-2 .swiper-slide {
    min-width: 59.7rem;
    width: 59.7rem !important;
    margin-right: 2rem;
  }
  .swiper__column {
    width: 100%;
    height: 72rem;
    margin-left: 5.8rem !important;
  }
  .swiper__column .swiper-wrapper {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    visibility: visible;
    transition-timing-function: ease-out;
  }
  .swiper__column .swiper-slide {
    min-width: 50rem;
    width: 50rem !important;
    margin-right: 3.6rem;
  }

  .swiper-button-prev,
.swiper-button-next {
    display: none;
  }

  .swiper-button-next:after,
.swiper-button-prev:after {
    display: none;
  }

  .swiper-button-prev__case-2,
.swiper-button-next__case-2 {
    display: none;
  }

  .swiper-button-prev__column,
.swiper-button-next__column {
    top: 15.4rem !important;
  }

  .swiper-button-prev__column {
    right: 9rem !important;
    left: auto !important;
  }

  .swiper-button-next__column {
    right: 4rem !important;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .swiper__top .swiper-pagination {
    text-align: right;
  }
  .swiper__top .swiper-horizontal > .swiper-pagination-bullets,
.swiper__top .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 24px;
    left: auto;
    right: 4rem;
  }
  .swiper__top .swiper-pagination-bullet {
    width: 6rem;
    height: 6px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: unset;
  }
  .swiper__top .swiper-pagination-bullet-active {
    background: #fff;
  }
  .swiper__case-2 {
    width: 100%;
    height: 41.8rem;
  }
  .swiper__case-2 .swiper-wrapper {
    transition-timing-function: ease-out;
  }
  .swiper__case-2 .swiper-slide {
    min-width: 63.7rem;
    width: 63.7rem !important;
  }
  .swiper__column {
    width: 100%;
    height: 41.193rem;
  }
  .swiper__column .swiper-wrapper {
    transition-timing-function: ease-out;
  }
  .swiper__column .swiper-slide {
    min-width: 36.536rem;
    width: 36.536rem !important;
    margin-right: 2.63rem;
  }

  .swiper-button-prev,
.swiper-button-next {
    width: 4rem !important;
    height: 4rem !important;
    border-radius: 50%;
    border: 1px solid #958464;
    z-index: 1 !important;
  }

  .swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 1.5rem !important;
    color: #958464;
  }

  .swiper-button-prev__case-2,
.swiper-button-next__case-2 {
    top: auto !important;
    bottom: -7rem;
  }

  .swiper-button-prev__case-2 {
    right: 9rem !important;
    left: auto !important;
  }

  .swiper-button-next__case-2 {
    right: 4rem !important;
  }

  .swiper-button-prev__column,
.swiper-button-next__column {
    top: 16.6rem !important;
  }

  .swiper-button-prev__column {
    right: 9rem !important;
    left: auto !important;
  }

  .swiper-button-next__column {
    right: 4rem !important;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .footer {
    width: 100%;
    background-color: #dfd6bb;
    font-family: "Gothic Medium BBB", sans-serif;
  }
  .footer__container {
    max-width: 144rem;
    margin: 0 auto;
    padding: 12rem 5.8rem 4.6rem 5.8rem;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .footer__left {
    padding: 2.8rem 0;
  }
  .footer__company-name {
    font-feature-settings: "palt";
    font-size: 3.4rem;
    letter-spacing: 0.05em;
    line-height: 1;
    margin-bottom: 3.9rem;
  }
  .footer__company-details {
    font-feature-settings: "palt";
    font-size: 2.6rem;
    letter-spacing: 0.05em;
    line-height: 1.69;
    margin-bottom: 7.1rem;
  }
  .footer__list {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 4.25rem;
  }
  .footer__link {
    line-height: 1.48;
    display: inline-block;
    padding-bottom: 1.25rem;
    border-bottom: 1.5px solid #000;
  }
  .footer__copy {
    margin-top: 10.55rem;
    font-size: 2rem;
    line-height: 2.2;
    display: flex;
    align-items: center;
  }
  .footer__copy span {
    font-size: 3.5rem;
    line-height: 1;
    display: inline-block;
    margin-right: 1rem;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .footer {
    width: 100%;
    background-color: #dfd6bb;
    font-family: "Gothic Medium BBB", sans-serif;
  }
  .footer__container {
    max-width: 144rem;
    margin: 0 auto;
    padding: 3.4rem 1.2rem 1.2rem 3.75rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .footer__left {
    padding: 2.8rem 0;
  }
  .footer__company-details, .footer__company-name {
    font-size: 1.3rem;
    line-height: 1.53;
    letter-spacing: 0.05em;
    font-feature-settings: "palt";
  }
  .footer__company-name {
    margin-bottom: 1.4rem;
  }
  .footer__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .footer__link {
    font-size: 1.5rem;
    line-height: 1.6;
    display: block;
    padding: 2.3rem;
  }
  .footer__copy {
    display: none;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5000;
    background-color: #efeee8;
    /* MENU
    ******************/
  }
  .header.fixed {
    background-color: #fff;
  }
  .header * {
    outline: 0;
  }
  .header__logo img {
    width: 32.5rem;
    height: 3.34rem;
  }
  .header__logo a {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: block;
  }
  .header__wrapper {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .header__nav {
    display: none;
  }
  .header__list {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    flex-direction: column;
    z-index: 5000;
    padding-top: 22.55rem;
    padding-bottom: 17.95rem;
    margin: 0 5.8rem;
  }
  .header__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4.75rem 2.7rem;
    font-size: 3.8rem;
    letter-spacing: 0.05em;
    line-height: 1;
  }
  .header__link .arrow {
    margin-left: 1rem;
    border-color: #b5b5b5;
    width: 2.9rem;
    height: 2.9rem;
  }
  .header__item {
    position: relative;
    cursor: pointer;
    width: 100%;
    margin: 0 5.8rem;
    border-bottom: 1.5px solid #b5b5b5;
  }
  .header__item:first-child {
    border-top: 1.5px solid #b5b5b5;
  }
  .header__item ul {
    display: none;
  }
  .header__item ul li {
    position: relative;
    transition: 0.3s ease;
    padding: 4.75rem 2.7rem 4.75rem;
    margin-left: 2.7rem;
    font-size: 3.8rem;
    letter-spacing: 0.05em;
    line-height: 1;
  }
  .header__item ul li:not(:last-child) {
    border-bottom: 1.5px solid #b5b5b5;
  }
  .header__item ul li a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transition: 0.5s ease;
    cursor: pointer;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .header__item ul li:hover {
    color: #968c6a;
  }
}
@media only screen and (max-width: 767px) {
  .header__link.is-active .arrow {
    transform: rotate(225deg);
  }
}
@media only screen and (max-width: 767px) {
  .header-menu {
    transition: 0.3s cubic-bezier(0.17, 0.935, 0.305, 1);
    transform: translateY(-100%);
    display: block;
    min-height: 100%;
    max-width: 100%;
    position: fixed;
    z-index: 4500;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    outline: 0;
    background-color: #fff;
  }
  .header-menu * {
    transition: 0.8s cubic-bezier(0.17, 0.935, 0.305, 1) 0.2s;
    opacity: 0;
  }
  .header-menu.hide *, .header-menu.show.hide * {
    opacity: 0;
  }
  .header-menu.show {
    transform: none;
  }
  .header-menu.show * {
    opacity: 1;
  }
}
@media only screen and (max-width: 767px) {
  .header.header-2 {
    background-color: transparent;
    position: fixed;
    top: 0;
  }
  .header.header-2.fixed {
    background-color: #fff;
  }
  .header.header-2 .header__link {
    color: #000;
    transition: 0.3s ease;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .header.header-2 .header__link:hover {
    color: #968c6a;
  }
  .header.header-2 .header__link:hover .arrow {
    border-bottom: 1px solid #968c6a;
    border-right: 1px solid #968c6a;
  }
}
@media only screen and (max-width: 767px) {
  .header.header-2 .header__item ul li {
    background-color: transparent;
  }
}
@media only screen and (max-width: 767px) {
  .header.header-2.inview {
    background-color: #efeee8;
    transition: 0.3s ease;
  }
  .header.header-2.inview .header__link {
    color: #000;
  }
  .header.header-2.inview .header__link .arrow {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .header.header-2.inview .header__link:hover {
    color: #968c6a;
  }
  .header.header-2.inview .header__link:hover .arrow {
    border-bottom: 1px solid #968c6a;
    border-right: 1px solid #968c6a;
  }
}
@media only screen and (max-width: 767px) {
  .header.header-2.inview .header__item ul li {
    background-color: #efeee8;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .header {
    width: 100%;
    position: fixed;
    z-index: 5000;
    background-color: #efeee8;
  }
  .header * {
    outline: 0;
  }
  .header-menu {
    display: none;
  }
  .header__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    padding-right: 2.7rem;
    padding-left: 4rem;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .header__list, .header__wrapper {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .header__list {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    z-index: 5000;
  }
  .header__link {
    display: block;
    padding: 4rem 1.3rem;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    line-height: 2;
  }
  .header__link .arrow {
    margin-left: 1rem;
  }
  .header__item {
    position: relative;
    cursor: pointer;
  }
  .header__item ul {
    position: absolute;
    left: 0;
    top: 10.8rem;
    display: none;
  }
  .header__item ul li {
    position: relative;
    width: 22rem;
    padding: 2rem 1.3rem;
    background-color: #efeee8;
    transition: 0.3s ease;
  }
  .header__item ul li a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transition: 0.5s ease;
    cursor: pointer;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .header__item ul li:hover {
    color: #968c6a;
  }
}
@media only screen and (min-width: 768px) {
  .header__link.is-active .arrow {
    transform: rotate(225deg);
  }
}
@media only screen and (min-width: 768px) {
  .header.header-2 {
    background-color: transparent;
    position: absolute;
  }
  .header.header-2 .header__link {
    color: #000;
    transition: 0.3s ease;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .header.header-2 .header__link:hover {
    color: #968c6a;
  }
  .header.header-2 .header__link:hover .arrow {
    border-bottom: 1px solid #968c6a;
    border-right: 1px solid #968c6a;
  }
}
@media only screen and (min-width: 768px) {
  .header.header-2 .header__item ul li {
    background-color: transparent;
  }
}
@media only screen and (min-width: 768px) {
  .header.header-2.inview {
    position: fixed;
    background-color: #efeee8;
    animation: fadeIn 0.3s ease;
  }
  .header.header-2.inview .header__link {
    color: #000;
  }
  .header.header-2.inview .header__link .arrow {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .header.header-2.inview .header__link:hover {
    color: #968c6a;
  }
  .header.header-2.inview .header__link:hover .arrow {
    border-bottom: 1px solid #968c6a;
    border-right: 1px solid #968c6a;
  }
}
@media only screen and (min-width: 768px) {
  .header.header-2.inview .header__item ul li {
    background-color: #efeee8;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .top {
    width: 100%;
    height: 80rem;
    height: 100vh;
    /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    z-index: 1;
  }
  .top__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .top__container-1, .top__container-2, .top__container-3 {
    width: 100%;
    height: 100%;
  }
  .top__container-1 {
    background: linear-gradient(rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), url("../images/index/top-1@sp.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .top__container-2 {
    background: linear-gradient(rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), url("../images/index/top-2@sp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .top__container-3 {
    background: linear-gradient(rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), url("../images/index/top-3@sp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .top__text {
    position: absolute;
    z-index: 10;
    bottom: 10rem;
    left: 4rem;
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 4.6rem;
    line-height: 1.95;
    letter-spacing: 0.05em;
    color: #fff;
    filter: drop-shadow(0 0 3px #000);
  }
  .top .swiper-pagination {
    display: none;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .top {
    width: 100%;
    height: 80rem;
    height: 100vh;
    /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    z-index: 1;
  }
  .top__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .top__container-1, .top__container-2, .top__container-3 {
    width: 100%;
    height: 100%;
  }
  .top__container-1 {
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url("../images/index/top-1@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .top__container-2 {
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url("../images/index/top-2@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .top__container-3 {
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url("../images/index/top-3@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .top__text {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding-left: 7.2rem;
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 2.8rem;
    line-height: 1.66;
    letter-spacing: 0.12em;
    color: #fff;
    filter: drop-shadow(0 0 3px #000);
  }
  .top .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.top .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 7px;
  }
  .top .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:last-child,
.top .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
    margin-right: 0;
  }
  .top .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:first-child,
.top .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:first-child {
    margin-left: 0;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .about {
    padding: 9.9rem 0 16rem;
    width: 100%;
  }
  .about__container {
    display: grid;
    justify-content: flex-end;
    margin-left: 3rem;
    position: relative;
  }
  .about__container img {
    display: block;
  }
  .about__text-container {
    padding-top: 44.2rem;
    grid-row: 2/span 1;
    grid-column: 1/span 1;
    margin-left: auto;
    margin-right: 5.8rem;
  }
  .about__heading-primary {
    color: #968c6a;
    font-size: 13.6rem;
    line-height: 0.7;
    letter-spacing: 0.05em;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
    justify-self: flex-start;
    grid-row: 2/span 1;
    grid-column: 1/span 1;
    position: absolute;
    top: 27rem;
    left: -11rem;
  }
  .about__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 4.1rem;
    letter-spacing: 0.05em;
    line-height: 1.95;
    margin-bottom: 8rem;
    font-feature-settings: "palt";
  }
  .about__text {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 3.1rem;
    line-height: 2.25;
    letter-spacing: 0.08em;
    margin-right: 0.5rem;
  }
  .about__image-container {
    position: relative;
    width: 56.7rem;
    margin-right: 4rem;
    grid-row: 1/span 1;
    grid-column: 1/span 1;
  }
  .about__image-1 {
    width: 100%;
    max-width: 56.7rem;
    height: auto;
    min-height: 79.1rem;
    object-position: left;
    object-fit: cover;
  }
  .about__image-2 {
    width: 37.5rem;
    height: auto;
    position: absolute;
    bottom: -15.7rem;
    left: -10.5rem;
  }
  .about__image-3 {
    width: 28.8rem;
    height: 19.2rem;
    position: absolute;
    bottom: -29rem;
    right: -1.9rem;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .about {
    padding: 13rem 0 42.3rem;
    max-width: 144rem;
    margin: 0 auto;
  }
  .about__container {
    display: grid;
    grid-template-columns: 12.5rem 1fr 1fr;
    margin-left: 9.5rem;
    position: relative;
  }
  .about__container img {
    display: block;
  }
  .about__text-container {
    padding-top: 15.8rem;
    width: 42.5rem;
  }
  .about__heading-primary {
    color: #968c6a;
    margin-top: 8.5rem;
    font-size: 9rem;
    line-height: 1;
    letter-spacing: 0.05em;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
    justify-self: flex-start;
  }
  .about__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 2.9rem;
    line-height: 1.86;
    letter-spacing: 0.05em;
    margin-bottom: 5.4rem;
    font-feature-settings: "palt";
  }
  .about__text {
    font-family: "Gothic Medium BBB", sans-serif;
    line-height: 2.25;
    letter-spacing: 0.12em;
  }
  .about__image-container {
    position: relative;
    width: min(47.85vw, 68.9rem);
  }
  .about__image-1 {
    width: 100%;
    max-width: 68.9rem;
    height: auto;
    min-height: 50rem;
    object-position: left;
    object-fit: cover;
  }
  .about__image-2 {
    width: 29vw;
    max-width: 42rem;
    height: auto;
    position: absolute;
    bottom: -14.1rem;
    left: -24rem;
  }
  .about__image-3 {
    width: 21vw;
    max-width: 29.2rem;
    height: 19.4rem;
    position: absolute;
    bottom: -27%;
    bottom: -24rem;
    left: 35%;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .feature {
    padding: 25.2rem 0 0;
    background-color: #efeee8;
  }
  .feature__wrapper {
    position: relative;
  }
  .feature__container {
    display: flex;
    flex-direction: column-reverse;
  }
  .feature__text-container {
    z-index: 10;
    background-color: #fff;
    padding-left: 8.5rem;
    padding-right: 8.5rem;
    padding-top: 22rem;
    padding-bottom: 12rem;
    margin-top: -10.6rem;
  }
  .feature__heading-primary {
    color: #968c6a;
    font-size: 14.5rem;
    position: relative;
    z-index: 100;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
    letter-spacing: 0.1em;
    line-height: 0.7;
    padding-left: 0.5rem;
    position: absolute;
    top: -13.4rem;
    left: 3rem;
  }
  .feature__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 4.8rem;
    line-height: 1;
    letter-spacing: 0.05em;
    margin-bottom: 6.8rem;
    font-feature-settings: "palt";
    text-align: center;
  }
  .feature__text {
    font-size: 3.1rem;
    line-height: 1.87;
    letter-spacing: 0.08em;
  }
  .feature__image-container {
    position: relative;
    z-index: 20;
    justify-self: end;
    margin-left: 5.8rem;
  }
  .feature__image-1 {
    object-position: left;
    width: 100%;
    height: 51rem;
    object-fit: cover;
  }
  .feature__button-container {
    margin-top: 8rem;
  }
  .feature__button-container .arrow {
    transform: translateX(9rem) rotate(45deg);
  }
  .feature__button-container .button {
    margin: 0 auto;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .feature {
    padding: 8.7rem 0 16rem;
    background-color: #efeee8;
  }
  .feature__wrapper {
    position: relative;
    max-width: 144rem;
    margin: 0 auto;
  }
  .feature__container {
    display: grid;
    grid-template-columns: 12.5rem 1fr;
    margin-left: 9.5rem;
  }
  .feature__text-container {
    position: absolute;
    z-index: 50;
    max-width: 72.4rem;
    background-color: #fff;
    padding-left: 22rem;
    padding-right: 5.6rem;
    padding-top: 8.6rem;
    padding-bottom: 6.6rem;
    bottom: -20.7%;
    left: 0;
  }
  .feature__heading-primary {
    color: #968c6a;
    font-size: 9rem;
    position: relative;
    z-index: 100;
    align-self: flex-end;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
    letter-spacing: 0.1em;
    margin-bottom: 8rem;
  }
  .feature__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 2.9rem;
    line-height: 2;
    letter-spacing: 0.08em;
    margin-bottom: 4rem;
    font-feature-settings: "palt";
  }
  .feature__text {
    line-height: 1.875;
    letter-spacing: 0.05em;
  }
  .feature__image-container {
    position: relative;
    z-index: 20;
    max-width: 115rem;
    justify-self: end;
  }
  .feature__image-1 {
    min-height: 70rem;
    object-position: left;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .feature__button-container {
    margin-top: 4rem;
  }
  .feature__button-container .arrow {
    transform: translateX(2.2rem) rotate(45deg);
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .oem-1 {
    padding: 21.2rem 0 0;
    background-color: #efeee8;
  }
  .oem-1__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    position: relative;
  }
  .oem-1__container {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  .oem-1__text-container {
    z-index: 10;
    background-color: #fff;
    padding-left: 8.5rem;
    padding-right: 8.5rem;
    padding-top: 22rem;
    padding-bottom: 12rem;
    margin-top: -10.6rem;
  }
  .oem-1__heading-primary {
    color: #968c6a;
    font-size: 13.5rem;
    letter-spacing: 0.06em;
    line-height: 0.7;
    padding-left: 0.5rem;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
    z-index: 100;
    position: absolute;
    top: -11.7rem;
    left: 3rem;
  }
  .oem-1__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 4.8rem;
    line-height: 1;
    letter-spacing: 0.05em;
    margin-bottom: 6.8rem;
    font-feature-settings: "palt";
    text-align: center;
  }
  .oem-1__text {
    font-size: 3.1rem;
    line-height: 1.87;
    letter-spacing: 0.08em;
  }
  .oem-1__image-container {
    position: relative;
    z-index: 20;
    justify-self: end;
    margin-left: 5.8rem;
  }
  .oem-1__image-1 {
    object-position: left;
    width: 100%;
    height: 51rem;
    object-fit: cover;
  }
  .oem-1__button-container {
    margin-top: 8rem;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .oem-1 {
    padding: 18rem 0 8.8rem;
    background-color: #efeee8;
  }
  .oem-1__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    position: relative;
  }
  .oem-1__container {
    display: grid;
    grid-template-columns: 1fr 29rem;
    padding-bottom: 15.9rem;
    position: relative;
  }
  .oem-1__text-container {
    position: absolute;
    z-index: 50;
    max-width: 72.4rem;
    background-color: #fff;
    padding-left: 10rem;
    padding-right: 12.6rem;
    padding-top: 8.6rem;
    padding-bottom: 6.6rem;
    bottom: 0;
    right: 0;
  }
  .oem-1__heading-primary {
    grid-column: 2/span 1;
    grid-row: 1;
    color: #968c6a;
    font-size: 12rem;
    line-height: 1;
    letter-spacing: 0.05em;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
    max-width: 7.24rem;
    position: absolute;
    right: 4.9rem;
    top: 25.5rem;
    z-index: 100;
    align-self: flex-end;
  }
  .oem-1__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 2.9rem;
    line-height: 2;
    letter-spacing: 0.05em;
    margin-bottom: 4.4rem;
  }
  .oem-1__text {
    line-height: 1.875;
    letter-spacing: 0.05em;
    max-width: 46.8rem;
  }
  .oem-1__image-container {
    grid-column: 1/span 1;
    grid-row: 1;
    position: relative;
    z-index: 20;
    max-width: 115rem;
    justify-self: start;
  }
  .oem-1__image-1 {
    min-height: 70rem;
    object-fit: cover;
    object-position: right;
    width: 100%;
    height: auto;
  }
  .oem-1__button-container {
    margin-top: 3rem;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .oem-2 {
    padding: 0 0 29.1rem;
    max-width: 144rem;
    margin: 0 auto;
  }
  .oem-2__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5.95rem;
    margin-right: 5.8rem;
  }
  .oem-2__for-box {
    width: 100%;
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: 4.2rem;
    row-gap: 4rem;
    padding-top: 10rem;
    border-top: 1.5px solid #8a805f;
  }
  .oem-2__for-box:not(:last-of-type) {
    padding-bottom: 11.2rem;
  }
  .oem-2__for-box:nth-of-type(1) .oem-2__image-container svg {
    width: 14.365rem;
    height: 7.708rem;
  }
  .oem-2__for-box:nth-of-type(2) .oem-2__image-container svg {
    width: 14.227rem;
    height: 9.254rem;
  }
  .oem-2__for-box:nth-of-type(3) .oem-2__image-container svg {
    width: 13.019rem;
    height: 10.23rem;
  }
  .oem-2__for-box:nth-of-type(2) .oem-2__heading-primary, .oem-2__for-box:nth-of-type(3) .oem-2__heading-primary {
    padding-top: 4rem;
  }
  .oem-2__for-box:nth-of-type(2) .oem-2__heading-secondary, .oem-2__for-box:nth-of-type(3) .oem-2__heading-secondary {
    padding-top: 4rem;
  }
  .oem-2__text-container {
    grid-column: 1/span 2;
  }
  .oem-2__heading-primary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 4.4rem;
    margin-top: 9rem;
    line-height: 1.22;
    grid-column: 2/span 1;
    grid-row: 1/span 1;
  }
  .oem-2__heading-secondary {
    font-family: "EB Garamond", serif;
    font-size: 2.8rem;
    line-height: 1.21;
    letter-spacing: 0.1em;
    color: #8a805f;
    grid-column: 2/span 1;
    grid-row: 1/span 1;
  }
  .oem-2__text {
    letter-spacing: 0.08em;
    margin-bottom: 8.1rem;
    margin-left: auto;
    margin-right: auto;
  }
  .oem-2__image-container {
    margin: 0 auto;
    width: 22.3rem;
    height: 22.3rem;
    border-radius: 50%;
    background-color: #efeee8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.1rem;
  }
  .oem-2__button-container {
    margin: 0 auto;
  }
  .oem-2__button-container .arrow {
    transform: translateX(9rem) rotate(45deg);
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .oem-2 {
    padding: 10rem 0 7.3rem;
    max-width: 144rem;
    margin: 0 auto;
  }
  .oem-2__container {
    max-width: 100rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: space-between;
    text-align: center;
  }
  .oem-2__for-box {
    margin: 0 auto;
  }
  .oem-2__heading-primary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }
  .oem-2__heading-secondary {
    font-family: "EB Garamond", serif;
    font-size: 1.8rem;
    line-height: 1;
    letter-spacing: 0.1em;
    color: #8a805f;
    margin-bottom: 1rem;
  }
  .oem-2__text {
    line-height: 1.625;
    letter-spacing: 0.05em;
    max-width: 28rem;
    margin-bottom: 3.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  .oem-2__image-container {
    margin: 0 auto;
    width: 19.2rem;
    height: 19.2rem;
    border-radius: 50%;
    background-color: #efeee8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.1rem;
  }
  .oem-2__button-container {
    margin: 0 auto;
  }
  .oem-2__button-container .arrow {
    transform: translateX(2.2rem) rotate(45deg);
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .case-1 {
    padding: 20rem 0 0;
    background-color: #efeee8;
  }
  .case-1__wrapper {
    position: relative;
  }
  .case-1__container {
    display: flex;
    flex-direction: column-reverse;
  }
  .case-1__text-container {
    z-index: 10;
    background-color: #fff;
    padding-left: 8.5rem;
    padding-right: 8.5rem;
    padding-top: 22rem;
    padding-bottom: 12rem;
    margin-top: -10.6rem;
  }
  .case-1__heading-primary {
    color: #968c6a;
    font-size: 13.6rem;
    position: relative;
    z-index: 100;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
    letter-spacing: 0.1em;
    line-height: 0.7;
    padding-left: 0.5rem;
    position: absolute;
    top: -13.4rem;
    left: 3rem;
  }
  .case-1__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 4.8rem;
    line-height: 1;
    letter-spacing: 0.05em;
    margin-bottom: 6.8rem;
    font-feature-settings: "palt";
    text-align: center;
  }
  .case-1__text {
    font-size: 3.1rem;
    line-height: 1.87;
    letter-spacing: 0.08em;
  }
  .case-1__image-container {
    position: relative;
    z-index: 20;
    justify-self: end;
    margin-left: 5.8rem;
  }
  .case-1__image-1 {
    object-position: left;
    width: 100%;
    height: 51rem;
    object-fit: cover;
  }
  .case-1__button-container {
    margin-top: 8rem;
  }
  .case-1__button-container .arrow {
    transform: translateX(9rem) rotate(45deg);
  }
  .case-1__button-container .button {
    margin: 0 auto;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .case-1 {
    padding: 30rem 0 23.65rem;
    background-color: #efeee8;
  }
  .case-1__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    position: relative;
  }
  .case-1__container {
    display: grid;
    grid-template-columns: 12.5rem 1fr;
    margin-left: 9.5rem;
  }
  .case-1__text-container {
    position: absolute;
    z-index: 50;
    max-width: 72.4rem;
    background-color: #fff;
    padding-left: 22rem;
    padding-right: 5.6rem;
    padding-top: 8.6rem;
    padding-bottom: 6.6rem;
    bottom: -20%;
    left: 0;
  }
  .case-1__heading-primary {
    color: #968c6a;
    font-size: 9rem;
    position: relative;
    z-index: 100;
    align-self: flex-end;
    padding-bottom: 14.5rem;
    letter-spacing: 0.1em;
    font-family: "EB Garamond", serif;
    writing-mode: vertical-rl;
  }
  .case-1__heading-secondary {
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 2.9rem;
    line-height: 2;
    letter-spacing: 0.05em;
    margin-bottom: 4.4rem;
  }
  .case-1__text {
    line-height: 1.875;
    letter-spacing: 0.05em;
  }
  .case-1__image-container {
    position: relative;
    z-index: 20;
    max-width: 115rem;
    justify-self: end;
  }
  .case-1__image-1 {
    min-height: 70rem;
    object-position: left;
    object-fit: cover;
    width: 100%;
    height: auto;
  }
  .case-1__button-container {
    margin-top: 3rem;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .case-2 {
    padding: 7.75rem 0 18.6rem;
    background-color: #efeee8;
  }
  .case-2__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    position: relative;
  }
  .case-2__heading-primary {
    font-family: "EB Garamond", serif;
    color: #8a805f;
    font-size: 8rem;
    letter-spacing: 0.05em;
    line-height: 1;
    white-space: nowrap;
    text-align: right;
    margin-right: 3.1rem;
    padding-bottom: 1rem;
    margin-bottom: 2.45rem;
  }
  .case-2__heading-secondary {
    color: #968c6a;
    font-size: 2.8rem;
    line-height: 1.07;
    letter-spacing: 0.05em;
    text-align: right;
    margin-right: 3.1rem;
  }
  .case-2__image {
    width: 59.7rem;
    height: 39.1rem;
    display: block;
  }
  .case-2-item.swiper-slide-active.sp-inview .case-2__text-container {
    transform: translateY(0);
  }
  .case-2__slide {
    position: relative;
    width: 59.7rem;
    height: 39.1rem;
    display: block;
    cursor: pointer;
    overflow: hidden;
  }
  .case-2__text-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 59.7rem;
    height: 21.8rem;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.98rem 1.31rem 1.89rem 2.51rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    transform: translateY(100%);
  }
  .case-2__text-container:hover {
    background-color: white;
  }
  .case-2__number {
    font-family: "Montserrat", sans-serif;
    font-size: 3.6rem;
    line-height: 1;
    letter-spacing: 0.1em;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #000;
  }
  .case-2__case {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 1;
    letter-spacing: 0.1em;
    margin-right: 0.7rem;
  }
  .case-2__text-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
  .case-2__text-box span {
    align-self: flex-end;
  }
  .case-2__heading-tertiary {
    align-self: flex-start;
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 3.4rem;
    line-height: 1.47;
    letter-spacing: 0.08em;
    color: #000;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
  }
  .case-2__heading-tertiary span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    margin-top: 0;
    margin-bottom: 0;
  }
  .case-2__heading-tertiary > span {
    width: 57.2rem;
    margin-right: 2rem;
  }
  .case-2__heading-tertiary .arrow {
    margin-bottom: 1.4rem;
    margin-right: 0.5em;
    flex-shrink: 0;
  }
  .case-2__text {
    font-size: 2.3rem;
    font-family: "Gothic Medium BBB", sans-serif;
    line-height: 1.34;
    letter-spacing: 0.08em;
    margin-bottom: 2rem;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .case-2 {
    padding: 0 0 25rem;
    background-color: #efeee8;
  }
  .case-2__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    position: relative;
  }
  .case-2__heading-primary {
    font-family: "EB Garamond", serif;
    color: #8a805f;
    font-size: 6.438rem;
    letter-spacing: 0.05em;
    white-space: nowrap;
    text-align: right;
    line-height: 1;
    margin-right: 4rem;
    padding-bottom: 1rem;
    margin-bottom: 1.25rem;
  }
  .case-2__heading-secondary {
    color: #968c6a;
    line-height: 1.875;
    letter-spacing: 0.1em;
    text-align: right;
    margin-right: 4rem;
  }
  .case-2__image {
    max-width: 63.7rem;
    width: 100%;
    height: auto;
    display: block;
  }
  .case-2__slide {
    position: relative;
    max-width: 63.7rem;
    display: block;
    cursor: pointer;
    overflow: hidden;
  }
  .case-2__slide:hover .case-2__text-container {
    transform: translateY(0);
  }
  .case-2__text-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 63.7rem;
    height: 17.8rem;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.98rem 1.31rem 1.89rem 2.51rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    transform: translateY(100%);
  }
  .case-2__text-container:hover {
    background-color: white;
  }
  .case-2__number {
    font-family: "Montserrat", sans-serif;
    font-size: 2.666rem;
    line-height: 1;
    letter-spacing: 0.1em;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #000;
  }
  .case-2__case {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.212rem;
    line-height: 1;
    letter-spacing: 0.1em;
    margin-right: 0.7rem;
  }
  .case-2__text-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
  .case-2__text-box span {
    align-self: flex-end;
  }
  .case-2__heading-tertiary {
    align-self: flex-start;
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 2.5rem;
    line-height: 1.8;
    letter-spacing: 0.18em;
    color: #000;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
  }
  .case-2__heading-tertiary span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 1;
    margin-top: 0;
    margin-bottom: 0;
  }
  .case-2__heading-tertiary > span {
    width: 57.2rem;
  }
  .case-2__heading-tertiary .arrow {
    margin-bottom: 1.4rem;
    margin-right: 0.5em;
  }
  .case-2__text {
    font-size: 1.6rem;
    font-family: "Gothic Medium BBB", sans-serif;
    line-height: 2.825;
    letter-spacing: 0.18em;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .column {
    background-color: #efeee8;
  }
  .column__wrapper {
    position: relative;
  }
  .column__container {
    background-color: #fff;
    width: 100%;
    padding-top: 7.61rem;
    padding-bottom: 10.2rem;
  }
  .column__heading-primary {
    font-family: "EB Garamond", serif;
    color: #8a805f;
    font-size: 8rem;
    margin-bottom: 2.15rem;
    white-space: nowrap;
    text-align: left;
    line-height: 1;
    letter-spacing: 0.05em;
    padding-left: 5.8rem;
  }
  .column__heading-secondary {
    color: #968c6a;
    font-size: 2.8rem;
    line-height: 1.07;
    letter-spacing: 0.05em;
    text-align: left;
    padding-left: 5.8rem;
  }
  .column__image {
    width: 50rem;
    height: 32.4rem;
    object-fit: cover;
    display: block;
    transition: 0.3s ease-in-out;
  }
  .column__slider-container {
    position: relative;
    width: 100%;
    width: 50rem;
    display: block;
    cursor: pointer;
    overflow: hidden;
  }
  .column__image-container {
    overflow: hidden;
  }
  .column__text-container {
    width: 50rem;
    height: 39.5rem;
    background-color: #f6f6f3;
    padding: 4.86rem 2.7rem 2.5rem 2.7rem;
    display: flex;
    flex-direction: column;
  }
  .column__text-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
  .column__text {
    color: #8a805f;
    font-size: 2.3rem;
    letter-spacing: 0.1em;
    line-height: 1.34;
  }
  .column__text-2 {
    font-size: 3.1rem;
    letter-spacing: 0.08em;
    line-height: 1;
  }
  .column__text-2 .arrow {
    align-self: flex-end;
    margin-bottom: 0.4rem;
    margin-left: -4px;
  }
  .column__button-container {
    margin-top: 7.34rem;
  }
  .column__button-container .arrow {
    transform: translateX(3rem) rotate(45deg);
  }
  .column__button-container .button {
    margin: 0 auto;
    font-size: 3.4rem;
    line-height: 1.9;
  }
  .column__heading-tertiary {
    align-self: flex-start;
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 3.4rem;
    line-height: 1.47;
    letter-spacing: 0.08em;
    color: #000;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
  }
  .column__heading-tertiary span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 3;
    margin-top: 0;
    margin-bottom: 0;
  }
  .column__heading-tertiary .case-2__text {
    font-size: 1.6rem;
    font-family: "Gothic Medium BBB", sans-serif;
    line-height: 2.825;
    letter-spacing: 0.18em;
  }
  .column__date {
    color: #8a805f;
    font-size: 2.3rem;
    letter-spacing: 0.03em;
    line-height: 1.34;
    margin-bottom: 3.7rem;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .column {
    padding-left: 9rem;
    background-color: #efeee8;
  }
  .column__wrapper {
    max-width: calc(144rem - 9rem);
    margin: 0 auto;
    position: relative;
  }
  .column__container {
    background-color: #fff;
    width: 100%;
    padding-top: 8.41rem;
    padding-left: 10.96rem;
    padding-bottom: 7.13rem;
  }
  .column__heading-primary {
    font-family: "EB Garamond", serif;
    color: #8a805f;
    font-size: 6.4rem;
    padding-bottom: 1rem;
    margin-bottom: 2.15rem;
    white-space: nowrap;
    text-align: left;
    line-height: 1;
    letter-spacing: 0.05em;
  }
  .column__heading-secondary {
    color: #968c6a;
    line-height: 1.875;
    letter-spacing: 0.1em;
    text-align: left;
  }
  .column__image {
    width: 36.536rem;
    height: 23.6rem;
    object-fit: cover;
    display: block;
    transition: 0.3s ease-in-out;
  }
  .column__slider-container {
    position: relative;
    width: 100%;
    max-width: 36.536rem;
    display: block;
    cursor: pointer;
    overflow: hidden;
  }
  .column__slider-container:hover .column__image {
    transform: scale(1.1);
  }
  .column__image-container {
    overflow: hidden;
  }
  .column__text-container {
    width: 100%;
    max-width: 36.536rem;
    height: 17.8rem;
    background-color: #f6f6f3;
    padding: 2rem 2.09rem 2rem 2.14rem;
    display: flex;
    flex-direction: column;
  }
  .column__text-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 0;
    align-items: flex-end;
    width: 100%;
  }
  .column__text {
    color: #8a805f;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    line-height: 1.75;
  }
  .column__text-2 {
    font-size: 1.7rem;
    letter-spacing: 0.1em;
    line-height: 1.41;
  }
  .column__text-2 span {
    align-self: flex-end;
    margin-bottom: 1px;
    margin-left: -4px;
  }
  .column__text-2.link {
    display: block;
    text-align: end;
    margin-right: 4rem;
    margin-top: 4.45rem;
  }
  .column__heading-tertiary {
    align-self: flex-start;
    font-family: "Gothic Medium BBB", sans-serif;
    font-size: 1.7rem;
    line-height: 1.53;
    letter-spacing: 0.06em;
    color: #000;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
  }
  .column__heading-tertiary span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    margin-top: 0;
    margin-bottom: 0;
  }
  .column__heading-tertiary .case-2__text {
    font-size: 1.6rem;
    font-family: "Gothic Medium BBB", sans-serif;
    line-height: 2.825;
    letter-spacing: 0.18em;
  }
  .column__date {
    color: #8a805f;
    font-size: 1.4rem;
    letter-spacing: 0.03em;
    line-height: 1.75;
    margin-bottom: 1rem;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .information {
    padding: 13.4rem 0 16rem;
    background-color: #efeee8;
  }
  .information__wrapper {
    max-width: 144rem;
    margin: 0 auto;
    padding: 0 5.8rem;
  }
  .information__container {
    max-width: 100rem;
    margin: 0 auto;
  }
  .information__heading-primary {
    font-size: 8rem;
    line-height: 1;
    letter-spacing: 0.05em;
    font-family: "EB Garamond", serif;
    margin-bottom: 2.35rem;
  }
  .information__heading-secondary {
    font-size: 2.8rem;
    letter-spacing: 0.01em;
  }
  .information__item {
    display: grid;
    align-items: center;
    grid-template-columns: 16.5rem 1fr;
    row-gap: 3.6rem;
  }
  .information__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 4.4rem;
    padding-bottom: 5.1rem;
    border-top: 1px solid #000;
  }
  .information__link:last-of-type {
    border-bottom: 1px solid #000;
    margin-bottom: 3.8rem;
  }
  .information__link .arrow {
    flex-shrink: 0;
  }
  .information__date {
    font-size: 2.3rem;
    line-height: 0.91;
    letter-spacing: 0.09em;
    padding-right: 3.3rem;
    width: 16.5rem;
    position: relative;
    grid-row: 1/span 1;
    grid-column: 1/span 1;
  }
  .information__date::before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #000;
    right: 0;
  }
  .information__cat {
    font-size: 2.3rem;
    line-height: 1;
    letter-spacing: 0.09em;
    max-width: 40rem;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
    margin-left: 2.2rem;
    flex-shrink: 0;
    grid-row: 1/span 1;
    grid-column: 2/span 1;
  }
  .information__cat span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 1;
    margin-top: 0;
    margin-bottom: 0;
  }
  .information__text {
    font-size: 3.1rem;
    line-height: 1.54;
    letter-spacing: 0.08em;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
    padding-right: 3rem;
    grid-row: 2/span 1;
    grid-column: 1/span 2;
  }
  .information__text span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    margin-top: 0;
    margin-bottom: 0;
  }
  .information__bottom {
    overflow: hidden;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .information__bottom:hover img.-zoom {
    transform: scale(1.05);
  }
}
@media only screen and (max-width: 767px) {
  .information__bottom img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    transition: 0.3s ease;
  }
}
@media only screen and (max-width: 767px) {
  .information__bottom-box {
    margin-top: 12.5rem;
    display: flex;
    flex-direction: column;
    gap: 6rem;
  }
}
@media only screen and (max-width: 767px) {
  .information__text-2 {
    display: block;
    text-align: end;
    font-size: 1.7rem;
    line-height: 1;
    letter-spacing: 0.02em;
  }
  .information__text-2 .arrow {
    margin-bottom: 2px;
    transition: 0.3s ease;
  }
}
@media only screen and (max-width: 767px) and (hover: hover) {
  .information__text-2:hover .arrow {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 767px) {
  .information__button-container {
    margin-top: 7.34rem;
  }
  .information__button-container .arrow {
    transform: translateX(3rem) rotate(45deg);
  }
  .information__button-container .button {
    margin: 0 auto;
    font-size: 3.4rem;
    line-height: 1.9;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .information {
    padding: 12rem 0;
    background-color: #efeee8;
  }
  .information__wrapper {
    max-width: 144rem;
    margin: 0 auto;
  }
  .information__container {
    max-width: 100rem;
    margin: 0 auto;
  }
  .information__heading-primary {
    padding-left: 5.5rem;
    font-size: 5rem;
    line-height: 1;
    letter-spacing: 0.02em;
    font-family: "EB Garamond", serif;
    margin-bottom: 2rem;
  }
  .information__heading-secondary {
    padding-left: 5.5rem;
    font-size: 1.4rem;
    letter-spacing: 0.01em;
  }
  .information__item {
    display: flex;
    align-items: center;
  }
  .information__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 3rem;
    padding-top: 3rem;
    padding-bottom: 2.8rem;
    padding-left: 4rem;
    border-top: 1px solid #000;
  }
  .information__link:last-of-type {
    border-bottom: 1px solid #000;
    margin-bottom: 3.8rem;
  }
  .information__date {
    font-size: 1.2rem;
    line-height: 1.75;
    letter-spacing: 0.09em;
    padding-right: 1.8rem;
    width: 8.9rem;
    position: relative;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .information__date {
    min-width: 70px;
  }
}
@media only screen and (min-width: 768px) {
  .information__date::before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #000;
    right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .information__cat {
    font-size: 1.2rem;
    line-height: 1.89;
    letter-spacing: 0.09em;
    border-radius: 20px;
    width: 8.3rem;
    max-width: 8.3rem;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
    margin-right: 2.4rem;
    margin-left: 1.2rem;
    flex-shrink: 0;
  }
  .information__cat span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 1;
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .information__text {
    line-height: 1.63;
    letter-spacing: 0.09em;
    word-break: break-all;
    overflow: hidden;
    backface-visibility: hidden;
    padding-right: 3rem;
  }
  .information__text span {
    visibility: visible;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 1;
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .information__bottom {
    overflow: hidden;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .information__bottom:hover img.-zoom {
    transform: scale(1.05);
  }
}
@media only screen and (min-width: 768px) {
  .information__bottom-box {
    margin-top: 12rem;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 768px) {
  .information__bottom img {
    width: 32.5rem;
    height: auto;
    display: block;
    object-fit: cover;
    transition: 0.3s ease;
  }
}
@media only screen and (min-width: 768px) {
  .information__text-2 {
    display: block;
    text-align: end;
    font-size: 1.7rem;
    line-height: 1;
    letter-spacing: 0.02em;
  }
  .information__text-2 .arrow {
    margin-bottom: 2px;
    transition: 0.3s ease;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .information__text-2:hover .arrow {
    opacity: 0.7;
  }
}
/*
* 1. sp
* 2. pc
*/
@media only screen and (max-width: 767px) {
  .contact {
    padding: 11.6rem 0 15.6rem;
  }
  .contact__container {
    display: flex;
    flex-direction: column;
  }
  .contact__heading-primary {
    font-size: 8rem;
    font-family: "EB Garamond", serif;
    line-height: 1.3;
    letter-spacing: 0.05em;
    text-align: center;
  }
  .contact__line {
    display: block;
    width: 36.4rem;
    height: 1px;
    background-color: #000;
    margin-bottom: 8.2rem;
    align-self: center;
    justify-self: center;
  }
  .contact__tel-container {
    border-left: 1.4rem solid #968c6a;
    padding-left: 5.5rem;
    margin-left: 5.8rem;
    margin-bottom: 6rem;
  }
  .contact__tel-text-1, .contact__email-text {
    line-height: 1.87;
    letter-spacing: 0.08em;
  }
  .contact__email-text {
    margin-top: -2px;
  }
  .contact__tel-text-2 {
    font-family: "Khula", sans-serif;
    font-size: 8.3rem;
    line-height: 1.22;
    letter-spacing: 0;
    margin-top: 1.2rem;
  }
  .contact__tel-text-3 {
    font-size: 2.4rem;
    line-height: 1.91;
    letter-spacing: 0.1em;
  }
  .contact__email-container {
    border-left: 1.4rem solid #968c6a;
    padding-left: 5.5rem;
    margin-left: 5.8rem;
  }
  .contact__button-container {
    margin-top: 3.1rem;
  }
  .contact__button-container .arrow {
    transform: translateX(5rem) rotate(45deg);
    margin-bottom: 0.7rem;
  }
}
/* PC
*******************/
@media only screen and (min-width: 768px) {
  .contact {
    padding: 10rem 0;
  }
  .contact__wrapper {
    max-width: 144rem;
    margin: 0 auto;
  }
  .contact__container {
    max-width: 100rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .contact__heading-primary {
    font-size: 6.4rem;
    font-family: "EB Garamond", serif;
    line-height: 1;
    letter-spacing: 0.05em;
  }
  .contact__line {
    display: block;
    width: 1px;
    height: 8.6rem;
    background-color: #000;
    margin: 0 8rem;
  }
  .contact__tel-text-1, .contact__email-text {
    font-size: 1.4rem;
    line-height: 1.71;
    letter-spacing: 0.1em;
  }
  .contact__email-text {
    margin-top: -2px;
  }
  .contact__tel-text-2 {
    font-family: "Khula", sans-serif;
    font-size: 4.4rem;
    line-height: 1.23;
    letter-spacing: 0.04em;
  }
  .contact__tel-text-3 {
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 0.1em;
  }
  .contact__email-container {
    margin-left: 3.8rem;
    align-self: end;
  }
  .contact__button-container {
    margin-top: 1rem;
  }
  .contact__button-container .arrow {
    margin-bottom: 2px;
  }
}
html {
  visibility: visible !important;
  opacity: 1 !important;
}