@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .about {
    padding: 9.9rem 0 16rem;
    width: 100%;

    &__container {
      display: grid;
      justify-content: flex-end;
      margin-left: 3rem;
      position: relative;
      & img {
        display: block;
      }
    }
    &__text-container {
      padding-top: 44.2rem;
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
      margin-left: auto;
      margin-right: 5.8rem;
    }
    &__heading-primary {
      color: $color-primary;
      font-size: 13.6rem;
      line-height: 0.7;
      letter-spacing: 0.05em;
      font-family: $font-garamond;
      writing-mode: vertical-rl;
      justify-self: flex-start;
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
      position: absolute;
      top: 27rem;
      left: -11rem;
    }
    &__heading-secondary {
      font-family: $font-aotf-pro;
      font-size: 4.1rem;
      letter-spacing: 0.05em;
      line-height: 1.95;
      margin-bottom: 8rem;
      font-feature-settings: 'palt';
    }
    &__text {
      font-family: $font-aotf-pro;
      font-size: 3.1rem;
      line-height: 2.25;
      letter-spacing: 0.08em;
      margin-right: 0.5rem;
    }
    &__image-container {
      position: relative;
      width: 56.7rem;
      margin-right: 4rem;
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }
    &__image-1 {
      width: 100%;
      max-width: 56.7rem;
      height: auto;
      min-height: 79.1rem;
      object-position: left;
      object-fit: cover;
    }
    &__image-2 {
      width: 37.5rem;
      height: auto;
      position: absolute;
      bottom: -15.7rem;
      left: -10.5rem;
    }
    &__image-3 {
      width: 28.8rem;
      height: 19.2rem;
      position: absolute;
      bottom: -29rem;
      right: -1.9rem;
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .about {
    padding: 13rem 0 42.3rem;
    max-width: $container-width;
    margin: 0 auto;

    &__container {
      display: grid;
      grid-template-columns: 12.5rem 1fr 1fr;
      margin-left: 9.5rem;
      position: relative;
      & img {
        display: block;
      }
    }
    &__text-container {
      padding-top: 15.8rem;
      width: 42.5rem;
    }
    &__heading-primary {
      color: $color-primary;
      margin-top: 8.5rem;
      font-size: 9rem;
      line-height: 1;
      letter-spacing: 0.05em;
      font-family: $font-garamond;
      writing-mode: vertical-rl;
      justify-self: flex-start;
    }
    &__heading-secondary {
      font-family: $font-aotf-pro;
      font-size: 2.9rem;
      line-height: 1.86;
      letter-spacing: 0.05em;
      margin-bottom: 5.4rem;
      font-feature-settings: 'palt';
    }
    &__text {
      font-family: $font-aotf-pro;
      line-height: 2.25;
      letter-spacing: 0.12em;
    }
    &__image-container {
      position: relative;
      @include pcmin('width', 689);
    }
    &__image-1 {
      width: 100%;
      max-width: 68.9rem;
      height: auto;
      min-height: 50rem; //要調整
      object-position: left;
      object-fit: cover;
    }
    &__image-2 {
      width: 29vw;
      max-width: 42rem;
      height: auto;
      position: absolute;

      bottom: -14.1rem;
      left: -24rem;
    }
    &__image-3 {
      width: 21vw;
      max-width: 29.2rem;
      height: 19.4rem;
      position: absolute;
      bottom: -27%;
      bottom: -24rem;
      left: 35%;
    }
  }
}
