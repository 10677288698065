@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .contact {
    padding: 11.6rem 0 15.6rem;

    &__wrapper {
    }
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__heading-primary {
      font-size: 8rem;
      font-family: $font-garamond;
      line-height: 1.3;
      letter-spacing: 0.05em;
      text-align: center;
    }
    &__line {
      display: block;
      width: 36.4rem;
      height: 1px;
      background-color: $color-black;
      margin-bottom: 8.2rem;
      align-self: center;
      justify-self: center;
    }
    &__tel-container {
      border-left: 1.4rem solid $color-primary;
      padding-left: 5.5rem;
      margin-left: 5.8rem;
      margin-bottom: 6rem;
    }
    &__tel-text-1,
    &__email-text {
      line-height: 1.87;
      letter-spacing: 0.08em;
    }
    &__email-text {
      margin-top: -2px;
    }
    &__tel-text-2 {
      font-family: $font-Khula;
      font-size: 8.3rem;
      line-height: 1.22;
      letter-spacing: 0;
      margin-top: 1.2rem;
    }
    &__tel-text-3 {
      font-size: 2.4rem;
      line-height: 1.91;
      letter-spacing: 0.1em;
    }
    &__email-container {
      border-left: 1.4rem solid $color-primary;
      padding-left: 5.5rem;
      margin-left: 5.8rem;
    }
    &__button-container {
      margin-top: 3.1rem;
      & .arrow {
        transform: translateX(5rem) rotate(45deg);
        margin-bottom: 0.7rem;
      }
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .contact {
    padding: 10rem 0;

    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
    }
    &__container {
      max-width: $content-width;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
      align-items: center;
    }
    &__heading-primary {
      font-size: 6.4rem;
      font-family: $font-garamond;
      line-height: 1;
      letter-spacing: 0.05em;
    }
    &__line {
      display: block;
      width: 1px;
      height: 8.6rem;
      background-color: $color-black;
      margin: 0 8rem;
    }
    &__tel-container {
    }
    &__tel-text-1,
    &__email-text {
      font-size: 1.4rem;
      line-height: 1.71;
      letter-spacing: 0.1em;
    }
    &__email-text {
      margin-top: -2px;
    }
    &__tel-text-2 {
      font-family: $font-Khula;
      font-size: 4.4rem;
      line-height: 1.23;
      letter-spacing: 0.04em;
    }
    &__tel-text-3 {
      font-size: 1.2rem;
      line-height: 1;
      letter-spacing: 0.1em;
    }
    &__email-container {
      margin-left: 3.8rem;
      align-self: end;
    }
    &__button-container {
      margin-top: 1rem;
      & .arrow {
        margin-bottom: 2px;
      }
    }
  }
}
