@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .oem-2 {
    padding: 0 0 29.1rem;
    max-width: $container-width;
    margin: 0 auto;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 5.95rem;
      margin-right: 5.8rem;
    }
    &__for-box {
      width: 100%;
      display: grid;
      grid-template-columns: min-content 1fr;
      column-gap: 4.2rem;
      row-gap: 4rem;
      padding-top: 10rem;
      border-top: 1.5px solid $color-primary-light-1;
      &:not(:last-of-type) {
        padding-bottom: 11.2rem;
      }
      &:nth-of-type(1) {
        & .oem-2__image-container {
          & svg {
            width: 14.365rem;
            height: 7.708rem;
          }
        }
      }
      &:nth-of-type(2) {
        & .oem-2__image-container {
          & svg {
            width: 14.227rem;
            height: 9.254rem;
          }
        }
      }
      &:nth-of-type(3) {
        & .oem-2__image-container {
          & svg {
            width: 13.019rem;
            height: 10.23rem;
          }
        }
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        & .oem-2__heading-primary {
          padding-top: 4rem;
        }
        & .oem-2__heading-secondary {
          padding-top: 4rem;
        }
      }
    }
    &__text-container {
      grid-column: 1 / span 2;
      // grid-row: 1 / span 1;
    }
    &__heading-primary {
      font-family: $font-aotf-pro;
      font-size: 4.4rem;
      margin-top: 9rem;
      line-height: 1.22;
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }
    &__heading-secondary {
      font-family: $font-garamond;
      font-size: 2.8rem;
      line-height: 1.21;
      letter-spacing: 0.1em;
      color: $color-primary-light-1;
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }
    &__text {
      letter-spacing: 0.08em;
      margin-bottom: 8.1rem;
      margin-left: auto;
      margin-right: auto;
    }
    &__image-container {
      margin: 0 auto;
      width: 22.3rem;
      height: 22.3rem;
      border-radius: 50%;
      background-color: $color-beige-1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.1rem;
    }

    &__button-container {
      margin: 0 auto;
      & .arrow {
        transform: translateX(9rem) rotate(45deg);
      }
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .oem-2 {
    padding: 10rem 0 7.3rem;
    max-width: $container-width;
    margin: 0 auto;

    &__container {
      max-width: 100rem;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      justify-content: space-between;
      text-align: center;
    }
    &__for-box {
      margin: 0 auto;
    }
    &__text-container {
    }
    &__heading-primary {
      font-family: $font-aotf-pro;
      font-size: 2.6rem;
      margin-bottom: 2rem;
    }
    &__heading-secondary {
      font-family: $font-garamond;
      font-size: 1.8rem;
      line-height: 1;
      letter-spacing: 0.1em;
      color: $color-primary-light-1;
      margin-bottom: 1rem;
    }
    &__text {
      line-height: 1.625;
      letter-spacing: 0.05em;
      max-width: 28rem;
      margin-bottom: 3.5rem;
      margin-left: auto;
      margin-right: auto;
    }
    &__image-container {
      margin: 0 auto;
      width: 19.2rem;
      height: 19.2rem;
      border-radius: 50%;
      background-color: $color-beige-1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.1rem;
    }

    &__button-container {
      margin: 0 auto;
      & .arrow {
        transform: translateX(2.2rem) rotate(45deg);
      }
    }
  }
}
