@charset "UTF-8";
/*
* 1. sp
* 2. pc
*/
@include respond(sp) {
  .information {
    padding: 13.4rem 0 16rem;
    background-color: $color-beige-1;

    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
      padding: 0 5.8rem;
    }
    &__container {
      max-width: $content-width;
      margin: 0 auto;
    }
    &__heading-primary {
      font-size: 8rem;
      line-height: 1;
      letter-spacing: 0.05em;
      font-family: $font-garamond;
      margin-bottom: 2.35rem;
    }
    &__heading-secondary {
      font-size: 2.8rem;
      letter-spacing: 0.01em;
    }
    &__list {
    }
    &__item {
      display: grid;
      align-items: center;
      grid-template-columns: 16.5rem 1fr;
      row-gap: 3.6rem;
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 4.4rem;
      padding-bottom: 5.1rem;
      border-top: 1px solid $color-black;
      &:last-of-type {
        border-bottom: 1px solid $color-black;
        margin-bottom: 3.8rem;
      }
      & .arrow {
        flex-shrink: 0;
      }
    }
    &__date {
      font-size: 2.3rem;
      line-height: 0.91;
      letter-spacing: 0.09em;
      padding-right: 3.3rem;
      width: 16.5rem;
      position: relative;
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: $color-black;
        right: 0;
      }
    }
    &__cat {
      font-size: 2.3rem;
      line-height: 1;
      letter-spacing: 0.09em;
      max-width: 40rem;
      @include line-clamp(1);
      margin-left: 2.2rem;
      flex-shrink: 0;
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
    }
    &__text {
      font-size: 3.1rem;
      line-height: 1.54;
      letter-spacing: 0.08em;
      @include line-clamp(2);
      padding-right: 3rem;
      grid-row: 2 / span 1;
      grid-column: 1 / span 2;
    }

    &__bottom {
      overflow: hidden;
      @include respond(hover) {
        &:hover {
          & img.-zoom {
            transform: scale(1.05);
          }
        }
      }
      & img {
        width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
        transition: 0.3s ease;
      }
      &-box {
        margin-top: 12.5rem;
        display: flex;
        flex-direction: column;
        gap: 6rem;
      }
    }
    &__text-2 {
      display: block;
      text-align: end;
      font-size: 1.7rem;
      line-height: 1;
      letter-spacing: 0.02em;
      & .arrow {
        margin-bottom: 2px;
        transition: 0.3s ease;
      }
      @include respond(hover) {
        &:hover {
          & .arrow {
            opacity: 0.7;
          }
        }
      }
    }
    &__button-container {
      margin-top: 7.34rem;
      & .arrow {
        transform: translateX(3rem) rotate(45deg);
      }
      & .button {
        margin: 0 auto;
        font-size: 3.4rem;
        line-height: 1.9;
      }
    }
  }
}

/* PC
*******************/
@include respond(tab-port) {
  .information {
    padding: 12rem 0;
    background-color: $color-beige-1;

    &__wrapper {
      max-width: $container-width;
      margin: 0 auto;
    }
    &__container {
      max-width: $content-width;
      margin: 0 auto;
    }
    &__heading-primary {
      padding-left: 5.5rem;
      font-size: 5rem;
      line-height: 1;
      letter-spacing: 0.02em;
      font-family: $font-garamond;
      margin-bottom: 2rem;
    }
    &__heading-secondary {
      padding-left: 5.5rem;
      font-size: 1.4rem;
      letter-spacing: 0.01em;
    }
    &__list {
    }
    &__item {
      display: flex;
      align-items: center;
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 3rem;
      padding-top: 3rem;
      padding-bottom: 2.8rem;
      padding-left: 4rem;
      border-top: 1px solid $color-black;
      &:last-of-type {
        border-bottom: 1px solid $color-black;
        margin-bottom: 3.8rem;
      }
    }
    &__date {
      font-size: 1.2rem;
      line-height: 1.75;
      letter-spacing: 0.09em;
      padding-right: 1.8rem;
      width: 8.9rem;
      position: relative;
      @media only screen and (max-width: 1200px) {
        min-width: 70px;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: $color-black;
        right: 0;
      }
    }
    &__cat {
      font-size: 1.2rem;
      line-height: 1.89;
      letter-spacing: 0.09em;
      border-radius: 20px;
      width: 8.3rem;
      max-width: 8.3rem;
      @include line-clamp(1);
      margin-right: 2.4rem;
      margin-left: 1.2rem;
      flex-shrink: 0;
    }
    &__text {
      line-height: 1.63;
      letter-spacing: 0.09em;
      @include line-clamp(1);
      padding-right: 3rem;
    }

    &__bottom {
      overflow: hidden;
      @include respond(hover) {
        &:hover {
          & img.-zoom {
            transform: scale(1.05);
          }
        }
      }
      &-box {
        margin-top: 12rem;
        display: flex;
        justify-content: space-between;
      }
      & img {
        width: 32.5rem;
        height: auto;
        display: block;
        object-fit: cover;
        transition: 0.3s ease;
      }
    }
    &__text-2 {
      display: block;
      text-align: end;
      font-size: 1.7rem;
      line-height: 1;
      letter-spacing: 0.02em;
      & .arrow {
        margin-bottom: 2px;
        transition: 0.3s ease;
      }
      @include respond(hover) {
        &:hover {
          & .arrow {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
